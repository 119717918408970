/**
 * Schema definitions
 */
export interface Schema {
    "v1-auth-agent-create-request"?:                              V1AuthAgentCreateRequest;
    "v1-auth-token-create-request"?:                              V1AuthTokenCreateRequest;
    "v1-auth-token-create-response"?:                             V1AuthTokenCreateResponse;
    "v1-blob-token-create-request"?:                              V1BlobTokenCreateRequest;
    "v1-blob-token-create-response"?:                             V1BlobTokenCreateResponse;
    "v1-company-code-create-request"?:                            V1CompanyCodeCreateRequest;
    "v1-company-code-create-response"?:                           V1CompanyCodeCreateResponse;
    "v1-company-code-show-response"?:                             V1CompanyCodeShowResponse;
    "v1-endpoints-cloud-setting-update-request"?:                 V1EndpointsCloudSettingUpdateRequest;
    "v1-endpoints-collective-create-request"?:                    V1EndpointsCollectiveCreateRequest;
    "v1-endpoints-collective-export-count-create-response"?:      V1EndpointsCollectiveExportCountCreateResponse;
    "v1-endpoints-collective-export-create-request"?:             V1EndpointsCollectiveExportCreateRequest;
    "v1-endpoints-collective-export-create-response"?:            V1EndpointsCollectiveExportCreateResponse;
    "v1-endpoints-collective-migrate-create-request"?:            V1EndpointsCollectiveMigrateCreateRequest;
    "v1-endpoints-create-copy-request"?:                          V1EndpointsCreateCopyRequest;
    "v1-endpoints-create-request"?:                               V1EndpointsCreateRequest;
    "v1-endpoints-create-response"?:                              V1EndpointsCreateResponse;
    "v1-endpoints-default-settings-show-response"?:               V1EndpointsDefaultSettingsShowResponse;
    "v1-endpoints-default-settings-update-request"?:              V1EndpointsDefaultSettingsUpdateRequest;
    "v1-endpoints-destroy-response"?:                             V1EndpointsDestroyResponse;
    "v1-endpoints-details-show-response"?:                        V1EndpointsDetailsShowResponse;
    "v1-endpoints-details-update-request"?:                       V1EndpointsDetailsUpdateRequest;
    "v1-endpoints-details-update-response"?:                      V1EndpointsDetailsUpdateResponse;
    "v1-endpoints-files-info-show-response"?:                     V1EndpointsFilesInfoShowResponse;
    "v1-endpoints-files-show-request"?:                           V1EndpointsFilesShowRequest;
    "v1-endpoints-files-show-response"?:                          V1EndpointsFilesShowResponse;
    "v1-endpoints-index-response"?:                               V1EndpointsIndexResponse;
    "v1-endpoints-mail-setting-show-response"?:                   V1EndpointsMailSettingShowResponse;
    "v1-endpoints-orders-patch-request"?:                         V1EndpointsOrdersPatchRequest;
    "v1-endpoints-orders-patch-response"?:                        V1EndpointsOrdersPatchResponse;
    "v1-endpoints-rules-show-response"?:                          V1EndpointsRulesShowResponse;
    "v1-endpoints-rules-update-request"?:                         V1EndpointsRulesUpdateRequest;
    "v1-endpoints-rules-update-response"?:                        V1EndpointsRulesUpdateResponse;
    "v1-endpoints-status-show-response"?:                         V1EndpointsStatusShowResponse;
    "v1-endpoints-unsubscribe-create-response"?:                  V1EndpointsUnsubscribeCreateResponse;
    "v1-endpoints-update-request"?:                               V1EndpointsUpdateRequest;
    "v1-endpoints-update-response"?:                              V1EndpointsUpdateResponse;
    "v1-entry-create-request"?:                                   V1EntryCreateRequest;
    "v1-entry-create-response"?:                                  V1EntryCreateResponse;
    "v1-entry-patch-cancel-request"?:                             V1EntryPatchCancelRequest;
    "v1-entry-show-list-request"?:                                V1EntryShowListRequest;
    "v1-entry-show-list-response"?:                               V1EntryShowListResponse;
    "v1-entry-update-cancel-request"?:                            V1EntryUpdateCancelRequest;
    "v1-entry-update-request"?:                                   V1EntryUpdateRequest;
    "v1-errors-error-response"?:                                  V1ErrorsErrorResponse;
    "v1-events-create-request"?:                                  V1EventsCreateRequest;
    "v1-events-create-response"?:                                 V1EventsCreateResponse;
    "v1-mail-show-request"?:                                      V1MailShowRequest;
    "v1-mail-show-response"?:                                     V1MailShowResponse;
    "v1-mfp-auth-create-request"?:                                V1MfpAuthCreateRequest;
    "v1-mfp-auth-create-response"?:                               V1MfpAuthCreateResponse;
    "v1-mfp-logs-create-request"?:                                V1MfpLogsCreateRequest;
    "v1-mfp-logs-create-response"?:                               V1MfpLogsCreateResponse;
    "v1-mfp-patch-request"?:                                      V1MfpPatchRequest;
    "v1-mfp-patch-response"?:                                     V1MfpPatchResponse;
    "v1-mfp-show-response"?:                                      V1MfpShowResponse;
    "v1-mfp-update-request"?:                                     V1MfpUpdateRequest;
    "v1-mfp-update-response"?:                                    V1MfpUpdateResponse;
    "v1-mfp-users-create-request"?:                               V1MfpUsersCreateRequest;
    "v1-mfp-users-index-response"?:                               V1MfpUsersIndexResponse;
    "v1-mfp-users-show-response"?:                                V1MfpUsersShowResponse;
    "v1-mfp-users-status-show-response"?:                         V1MfpUsersStatusShowResponse;
    "v1-mfp-users-update-request"?:                               V1MfpUsersUpdateRequest;
    "v1-notice-create-request"?:                                  V1NoticeCreateRequest;
    "v1-notice-create-response"?:                                 V1NoticeCreateResponse;
    "v1-notice-index-response"?:                                  V1NoticeIndexResponse;
    "v1-objects-client-app"?:                                     V1ObjectsClientApp;
    "v1-objects-collective-invite"?:                              V1ObjectsCollectiveInvite;
    "v1-objects-collective-mfp"?:                                 V1ObjectsCollectiveMfp;
    "v1-objects-collective-status"?:                              V1ObjectsCollectiveStatus;
    "v1-objects-default-group"?:                                  V1ObjectsDefaultGroup;
    "v1-objects-endpoint-default-setting"?:                       V1ObjectsEndpointDefaultSetting;
    "v1-objects-endpoint-extension"?:                             V1ObjectsEndpointExtension;
    "v1-objects-endpoint-file"?:                                  V1ObjectsEndpointFile;
    "v1-objects-endpoint-info"?:                                  V1ObjectsEndpointInfo;
    "v1-objects-endpoint-order"?:                                 V1ObjectsEndpointOrder;
    "v1-objects-endpoints-button-color"?:                         V1ObjectsEndpointsButtonColor;
    "v1-objects-endpoints-cloud-setting"?:                        V1ObjectsEndpointsCloudSetting;
    "v1-objects-endpoints-collective-setting"?:                   V1ObjectsEndpointsCollectiveSetting;
    "v1-objects-endpoints-default-setting-doc-name-format"?:      V1ObjectsEndpointsDefaultSettingDocNameFormat;
    "v1-objects-endpoints-default-setting-mfp-info"?:             V1ObjectsEndpointsDefaultSettingMfpInfo;
    "v1-objects-endpoints-default-setting-param-data"?:           V1ObjectsEndpointsDefaultSettingParamData;
    "v1-objects-endpoints-default-setting-pdf-attribute-data"?:   V1ObjectsEndpointsDefaultSettingPDFAttributeData;
    "v1-objects-endpoints-default-setting-print-attribute-data"?: V1ObjectsEndpointsDefaultSettingPrintAttributeData;
    "v1-objects-endpoints-default-setting-scan-attribute-data"?:  V1ObjectsEndpointsDefaultSettingScanAttributeData;
    "v1-objects-endpoints-mail-setting"?:                         V1ObjectsEndpointsMailSetting;
    "v1-objects-endpoints-permissions-application"?:              V1ObjectsEndpointsPermissionsApplication;
    "v1-objects-endpoints-permissions-group"?:                    V1ObjectsEndpointsPermissionsGroup;
    "v1-objects-endpoints-permissions-mfp"?:                      V1ObjectsEndpointsPermissionsMfp;
    "v1-objects-endpoints-permissions-user"?:                     V1ObjectsEndpointsPermissionsUser;
    "v1-objects-entry-cloud"?:                                    V1ObjectsEntryCloud;
    "v1-objects-entry-index"?:                                    V1ObjectsEntryIndex;
    "v1-objects-entry-list-index"?:                               V1ObjectsEntryListIndex;
    "v1-objects-entry-products"?:                                 V1ObjectsEntryProducts;
    "v1-objects-entry-sort-type"?:                                V1ObjectsEntrySortType;
    "v1-objects-entry-state"?:                                    V1ObjectsEntryState;
    "v1-objects-eventlog-aggregate-results"?:                     V1ObjectsEventlogAggregateResults;
    "v1-objects-extension"?:                                      V1ObjectsExtension;
    "v1-objects-group"?:                                          V1ObjectsGroup;
    "v1-objects-jsonfile"?:                                       V1ObjectsJsonfile;
    "v1-objects-mail-event"?:                                     V1ObjectsMailEvent;
    "v1-objects-mail-resource-type"?:                             V1ObjectsMailResourceType;
    "v1-objects-mfp-log-info"?:                                   V1ObjectsMfpLogInfo;
    "v1-objects-mfp-user-info"?:                                  V1ObjectsMfpUserInfo;
    "v1-objects-modify-response-status-enum"?:                    V1ObjectsModifyResponseStatusEnum;
    "v1-objects-notice-destination"?:                             V1ObjectsNoticeDestination;
    "v1-objects-notice-info"?:                                    V1ObjectsNoticeInfo;
    "v1-objects-pagination"?:                                     V1ObjectsPagination;
    "v1-objects-pass-export-const"?:                              V1ObjectsPassExportConst;
    "v1-objects-registered-user-enum"?:                           V1ObjectsRegisteredUserEnum;
    "v1-objects-service-enum"?:                                   V1ObjectsServiceEnum;
    "v1-objects-sort"?:                                           V1ObjectsSort;
    "v1-objects-spooler-batch-task"?:                             V1ObjectsSpoolerBatchTask;
    "v1-objects-spoolers-index"?:                                 V1ObjectsSpoolersIndex;
    "v1-objects-spoolers-process-history"?:                       V1ObjectsSpoolersProcessHistory;
    "v1-objects-spoolers-process-type-enum"?:                     V1ObjectsSpoolersProcessTypeEnum;
    "v1-objects-spoolers-source-enum"?:                           V1ObjectsSpoolersSourceEnum;
    "v1-objects-sync-obejcts-status"?:                            V1ObjectsSyncObejctsStatus;
    "v1-objects-sync-status-enum"?:                               V1ObjectsSyncStatusEnum;
    "v1-objects-sync-target"?:                                    V1ObjectsSyncTarget;
    "v1-objects-user"?:                                           V1ObjectsUser;
    "v1-objects-wordbook"?:                                       V1ObjectsWordbook;
    "v1-objects-workspace"?:                                      V1ObjectsWorkspace;
    "v1-objects-workspacemfp"?:                                   V1ObjectsWorkspacemfp;
    "v1-objects-workspacemfpuser"?:                               V1ObjectsWorkspacemfpuser;
    "v1-objects-workspaces-cloud-setting"?:                       V1ObjectsWorkspacesCloudSetting;
    "v1-objects-workspaces-mfp-combination"?:                     V1ObjectsWorkspacesMfpCombination;
    "v1-objects-workspaces-mfp-info"?:                            V1ObjectsWorkspacesMfpInfo;
    "v1-objects-workspaces-notification-target-user"?:            V1ObjectsWorkspacesNotificationTargetUser;
    "v1-objects-workspaces-organization-detail"?:                 V1ObjectsWorkspacesOrganizationDetail;
    "v1-objects-workspaceuser-large"?:                            V1ObjectsWorkspaceuserLarge;
    "v1-objects-workspaceuser-medium"?:                           V1ObjectsWorkspaceuserMedium;
    "v1-objects-workspaceusers-email-notification"?:              V1ObjectsWorkspaceusersEmailNotification;
    "v1-objects-x-storage"?:                                      V1ObjectsXStorage;
    "v1-objects-x-storages-order-history"?:                       V1ObjectsXStoragesOrderHistory;
    "v1-objects-x-storages-order-info"?:                          V1ObjectsXStoragesOrderInfo;
    "v1-objects-x-storages-order-master"?:                        V1ObjectsXStoragesOrderMaster;
    "v1-objects-x-storages-status"?:                              V1ObjectsXStoragesStatus;
    "v1-objects-xstorage-file"?:                                  V1ObjectsXstorageFile;
    "v1-spoolers-batch-create-request"?:                          V1SpoolersBatchCreateRequest;
    "v1-spoolers-batch-show-response"?:                           V1SpoolersBatchShowResponse;
    "v1-spoolers-batch-status-update-request"?:                   V1SpoolersBatchStatusUpdateRequest;
    "v1-spoolers-destroy-response"?:                              V1SpoolersDestroyResponse;
    "v1-spoolers-download-show-response"?:                        V1SpoolersDownloadShowResponse;
    "v1-spoolers-index-request"?:                                 V1SpoolersIndexRequest;
    "v1-spoolers-index-response"?:                                V1SpoolersIndexResponse;
    "v1-spoolers-patch-request"?:                                 V1SpoolersPatchRequest;
    "v1-sync-create-request"?:                                    V1SyncCreateRequest;
    "v1-sync-create-response"?:                                   V1SyncCreateResponse;
    "v1-uploadsync-create-request"?:                              V1UploadsyncCreateRequest;
    "v1-uploadsync-create-response"?:                             V1UploadsyncCreateResponse;
    "v1-uploadsync-patch-request"?:                               V1UploadsyncPatchRequest;
    "v1-uploadsync-patch-response"?:                              V1UploadsyncPatchResponse;
    "v1-uploadsync-status-patch-response"?:                       V1UploadsyncStatusPatchResponse;
    "v1-user-create-request"?:                                    V1UserCreateRequest;
    "v1-user-create-response"?:                                   V1UserCreateResponse;
    "v1-user-mfps-show-response"?:                                V1UserMfpsShowResponse;
    "v1-user-update-request"?:                                    V1UserUpdateRequest;
    "v1-user-update-response"?:                                   V1UserUpdateResponse;
    "v1-wordbooks-index-request"?:                                V1WordbooksIndexRequest;
    "v1-wordbooks-index-response"?:                               V1WordbooksIndexResponse;
    "v1-wordbooks-show-response"?:                                V1WordbooksShowResponse;
    "v1-workspace-analytics-download-create-request"?:            V1WorkspaceAnalyticsDownloadCreateRequest;
    "v1-workspace-analytics-download-create-response"?:           V1WorkspaceAnalyticsDownloadCreateResponse;
    "v1-workspace-analytics-notification-config-show-response"?:  V1WorkspaceAnalyticsNotificationConfigShowResponse;
    "v1-workspace-analytics-notification-config-update-request"?: V1WorkspaceAnalyticsNotificationConfigUpdateRequest;
    "v1-workspace-cloud-setting-index-response"?:                 V1WorkspaceCloudSettingIndexResponse;
    "v1-workspace-cloud-setting-show-response"?:                  V1WorkspaceCloudSettingShowResponse;
    "v1-workspace-cloud-setting-update-request"?:                 V1WorkspaceCloudSettingUpdateRequest;
    "v1-workspace-cloud-setting-update-response"?:                V1WorkspaceCloudSettingUpdateResponse;
    "v1-workspace-create-request"?:                               V1WorkspaceCreateRequest;
    "v1-workspace-create-response"?:                              V1WorkspaceCreateResponse;
    "v1-workspace-destroy-response"?:                             V1WorkspaceDestroyResponse;
    "v1-workspace-eventlog-aggregate-results-index-request"?:     V1WorkspaceEventlogAggregateResultsIndexRequest;
    "v1-workspace-eventlog-aggregate-results-index-response"?:    V1WorkspaceEventlogAggregateResultsIndexResponse;
    "v1-workspace-groups-create-request"?:                        V1WorkspaceGroupsCreateRequest;
    "v1-workspace-groups-index-response"?:                        V1WorkspaceGroupsIndexResponse;
    "v1-workspace-groups-show-response"?:                         V1WorkspaceGroupsShowResponse;
    "v1-workspace-groups-update-request"?:                        V1WorkspaceGroupsUpdateRequest;
    "v1-workspace-index-response"?:                               V1WorkspaceIndexResponse;
    "v1-workspace-invite-create-request"?:                        V1WorkspaceInviteCreateRequest;
    "v1-workspace-invite-create-response"?:                       V1WorkspaceInviteCreateResponse;
    "v1-workspace-invite-mail-create-request"?:                   V1WorkspaceInviteMailCreateRequest;
    "v1-workspace-invite-mail-create-response"?:                  V1WorkspaceInviteMailCreateResponse;
    "v1-workspace-invite-url-create-request"?:                    V1WorkspaceInviteURLCreateRequest;
    "v1-workspace-invite-url-create-response"?:                   V1WorkspaceInviteURLCreateResponse;
    "v1-workspace-invite-verify-create-request"?:                 V1WorkspaceInviteVerifyCreateRequest;
    "v1-workspace-invite-verify-create-response"?:                V1WorkspaceInviteVerifyCreateResponse;
    "v1-workspace-invite-verify-update-request"?:                 V1WorkspaceInviteVerifyUpdateRequest;
    "v1-workspace-invite-verify-update-response"?:                V1WorkspaceInviteVerifyUpdateResponse;
    "v1-workspace-mfps-collective-create-request"?:               V1WorkspaceMfpsCollectiveCreateRequest;
    "v1-workspace-mfps-collective-create-response"?:              V1WorkspaceMfpsCollectiveCreateResponse;
    "v1-workspace-mfps-create-request"?:                          V1WorkspaceMfpsCreateRequest;
    "v1-workspace-mfps-create-response"?:                         V1WorkspaceMfpsCreateResponse;
    "v1-workspace-mfps-destroy-response"?:                        V1WorkspaceMfpsDestroyResponse;
    "v1-workspace-mfps-index-response"?:                          V1WorkspaceMfpsIndexResponse;
    "v1-workspace-mfps-list-index-request"?:                      V1WorkspaceMfpsListIndexRequest;
    "v1-workspace-mfps-list-index-response"?:                     V1WorkspaceMfpsListIndexResponse;
    "v1-workspace-mfps-patch-request"?:                           V1WorkspaceMfpsPatchRequest;
    "v1-workspace-mfps-registers-index-response"?:                V1WorkspaceMfpsRegistersIndexResponse;
    "v1-workspace-mfps-show-response"?:                           V1WorkspaceMfpsShowResponse;
    "v1-workspace-mfps-update-request"?:                          V1WorkspaceMfpsUpdateRequest;
    "v1-workspace-mfps-update-response"?:                         V1WorkspaceMfpsUpdateResponse;
    "v1-workspace-mfps-users-create-request"?:                    V1WorkspaceMfpsUsersCreateRequest;
    "v1-workspace-mfps-users-create-response"?:                   V1WorkspaceMfpsUsersCreateResponse;
    "v1-workspace-mfps-users-index-response"?:                    V1WorkspaceMfpsUsersIndexResponse;
    "v1-workspace-password-reset-create-response"?:               V1WorkspacePasswordResetCreateResponse;
    "v1-workspace-profile-show-response"?:                        V1WorkspaceProfileShowResponse;
    "v1-workspace-remind-me-index-response"?:                     V1WorkspaceRemindMeIndexResponse;
    "v1-workspace-show-response"?:                                V1WorkspaceShowResponse;
    "v1-workspace-update-request"?:                               V1WorkspaceUpdateRequest;
    "v1-workspace-update-response"?:                              V1WorkspaceUpdateResponse;
    "v1-workspaceuser-collective-destroy-request"?:               V1WorkspaceuserCollectiveDestroyRequest;
    "v1-workspaceuser-collective-destroy-response"?:              V1WorkspaceuserCollectiveDestroyResponse;
    "v1-workspaceuser-create-request"?:                           V1WorkspaceuserCreateRequest;
    "v1-workspaceuser-create-response"?:                          V1WorkspaceuserCreateResponse;
    "v1-workspaceuser-destroy-response"?:                         V1WorkspaceuserDestroyResponse;
    "v1-workspaceuser-index-response"?:                           V1WorkspaceuserIndexResponse;
    "v1-workspaceuser-invite-collective-create-request"?:         V1WorkspaceuserInviteCollectiveCreateRequest;
    "v1-workspaceuser-invite-collective-create-response"?:        V1WorkspaceuserInviteCollectiveCreateResponse;
    "v1-workspaceuser-invite-collective-export-create-request"?:  V1WorkspaceuserInviteCollectiveExportCreateRequest;
    "v1-workspaceuser-invite-collective-export-create-response"?: V1WorkspaceuserInviteCollectiveExportCreateResponse;
    "v1-workspaceuser-invite-resend-create-request"?:             V1WorkspaceuserInviteResendCreateRequest;
    "v1-workspaceuser-invite-resend-create-response"?:            V1WorkspaceuserInviteResendCreateResponse;
    "v1-workspaceuser-invite-verify-create-request"?:             V1WorkspaceuserInviteVerifyCreateRequest;
    "v1-workspaceuser-invite-verify-create-response"?:            V1WorkspaceuserInviteVerifyCreateResponse;
    "v1-workspaceuser-invite-verify-update-request"?:             V1WorkspaceuserInviteVerifyUpdateRequest;
    "v1-workspaceuser-invite-verify-update-response"?:            V1WorkspaceuserInviteVerifyUpdateResponse;
    "v1-workspaceuser-password-reset-create-request"?:            V1WorkspaceuserPasswordResetCreateRequest;
    "v1-workspaceuser-password-reset-create-response"?:           V1WorkspaceuserPasswordResetCreateResponse;
    "v1-workspaceuser-password-reset-update-request"?:            V1WorkspaceuserPasswordResetUpdateRequest;
    "v1-workspaceuser-password-reset-update-response"?:           V1WorkspaceuserPasswordResetUpdateResponse;
    "v1-workspaceuser-password-reset-verify-create-request"?:     V1WorkspaceuserPasswordResetVerifyCreateRequest;
    "v1-workspaceuser-password-reset-verify-create-response"?:    V1WorkspaceuserPasswordResetVerifyCreateResponse;
    "v1-workspaceuser-show-response"?:                            V1WorkspaceuserShowResponse;
    "v1-workspaceuser-update-request"?:                           V1WorkspaceuserUpdateRequest;
    "v1-workspaceuser-update-response"?:                          V1WorkspaceuserUpdateResponse;
    "v1-workspaceuser-x-storage-status-index-response"?:          V1WorkspaceuserXStorageStatusIndexResponse;
    "v1-x-storages-auth-create-request"?:                         V1XStoragesAuthCreateRequest;
    "v1-x-storages-auth-create-response"?:                        V1XStoragesAuthCreateResponse;
    "v1-x-storages-author-link-index-response"?:                  V1XStoragesAuthorLinkIndexResponse;
    "v1-x-storages-create-request"?:                              V1XStoragesCreateRequest;
    "v1-x-storages-create-response"?:                             V1XStoragesCreateResponse;
    "v1-x-storages-destroy-response"?:                            V1XStoragesDestroyResponse;
    "v1-x-storages-files-show-request"?:                          V1XStoragesFilesShowRequest;
    "v1-x-storages-files-show-response"?:                         V1XStoragesFilesShowResponse;
    "v1-x-storages-index-request"?:                               V1XStoragesIndexRequest;
    "v1-x-storages-index-response"?:                              V1XStoragesIndexResponse;
    "v1-x-storages-order-create-request"?:                        V1XStoragesOrderCreateRequest;
    "v1-x-storages-order-detail-index-response"?:                 V1XStoragesOrderDetailIndexResponse;
    "v1-x-storages-order-index-response"?:                        V1XStoragesOrderIndexResponse;
    "v1-x-storages-order-link-show-response"?:                    V1XStoragesOrderLinkShowResponse;
    "v1-x-storages-order-update-request"?:                        V1XStoragesOrderUpdateRequest;
    "v1-x-storages-update-request"?:                              V1XStoragesUpdateRequest;
    "v1-x-storages-update-response"?:                             V1XStoragesUpdateResponse;
    [property: string]: any;
}

/**
 * 代理ログインリクエスト
 */
export interface V1AuthAgentCreateRequest {
    /**
     * 代理ログインするメンバーのメールアドレス
     */
    targetEmail: string;
    /**
     * 代理ログインするメンバーの主キー
     */
    targetMemberId: string;
}

/**
 * サインイン・リクエストの定義。
 */
export interface V1AuthTokenCreateRequest {
    /**
     * Authorization Code
     */
    authCode: string;
}

/**
 * 指定したユーザーの認証を行う。
 */
export interface V1AuthTokenCreateResponse {
    /**
     * token
     */
    authToken: string;
}

/**
 * アイコン、ロゴをアップロードするためのURLを作成するリクエスト
 */
export interface V1BlobTokenCreateRequest {
    /**
     * アップロードするファイルの種類
     */
    blobType: BlobType;
    /**
     * group-logoの場合、groups._idを指定する
     */
    groupId?: string;
    /**
     * アップロードファイルの名前
     */
    name: string;
}

/**
 * アップロードするファイルの種類
 */
export enum BlobType {
    GroupLogo = "group-logo",
    UserAvatar = "user-avatar",
    WorkspaceLogo = "workspace-logo",
}

/**
 * アイコン、ロゴをアップロードするためのURLレスポンス
 */
export interface V1BlobTokenCreateResponse {
    /**
     * blobを保管するコンテナ名
     */
    container: string;
    /**
     * destination of the blob
     */
    destination: string;
    /**
     * blobストレージのパス
     */
    filePath: string;
    /**
     * blobの名前
     */
    name: string;
    /**
     * blobの`sas`(共有アクセスポリシー)
     */
    sas: string;
}

/**
 * 企業コード認証リクエスト
 */
export interface V1CompanyCodeCreateRequest {
    /**
     * company code for auth
     */
    code: string;
}

/**
 * 企業コード認証のレスポンス
 */
export interface V1CompanyCodeCreateResponse {
    /**
     * auth code
     */
    authorization: string;
}

/**
 * 企業コード認証トークンの検証レスポンス
 */
export interface V1CompanyCodeShowResponse {
    /**
     * トークンが有効かどうか
     */
    authorized: boolean;
}

/**
 * クラウドストレージの更新リクエスト
 */
export interface V1EndpointsCloudSettingUpdateRequest {
    docNameFormat: V1ObjectsEndpointsDefaultSettingDocNameFormat;
    endpoint:      V1ObjectsEndpointsCloudSetting;
    /**
     * vocabulary
     */
    wordListData: string[];
    /**
     * vocabulary
     */
    wordListData02: string[];
}

/**
 * Doc name format object
 *
 * ファイル名形式
 */
export interface V1ObjectsEndpointsDefaultSettingDocNameFormat {
    /**
     * date format
     */
    dateFormat: string;
    /**
     * 日付セパレーター(., -, _, c, ""(空文字))
     * (cは漢字区切り)
     * 空文字は「なし」のパラメータとして扱う
     */
    dateSeparator?: string;
    /**
     * Can upload even if user does not enter any characters
     */
    fileOptional?: boolean;
    /**
     * fixed item data
     */
    fixed: string[];
    /**
     * format of file name
     */
    itemList: string;
    /**
     * Switch lock
     */
    lockEdit?: boolean;
    /**
     * Switch lock from shared endpoint
     */
    orgLockEdit?: boolean;
    /**
     * separator between part of itemList
     */
    separator: string;
    /**
     * Sequence type
     */
    sequenceType?: number;
    /**
     * 単語帳名1
     */
    wordListName01?: string;
    /**
     * 単語帳名2
     */
    wordListName02?: string;
}

/**
 * クラウドストレージ更新用のエンドポイント情報
 */
export interface V1ObjectsEndpointsCloudSetting {
    /**
     * List of email to be sent to
     */
    address?: string[];
    /**
     * can download from or not
     */
    downloadable: boolean;
    /**
     * Maximum attechment size
     */
    fileMaxSize?: number;
    /**
     * path to Endpoint (on cloud)
     */
    path?: string;
    /**
     * id of Endpoint (on cloud)
     */
    pathId?: string;
    /**
     * 共有フラグ
     */
    share: number;
    /**
     * Subject to be inclueded in email
     */
    subject?: string;
    /**
     * Email body template
     */
    template?: string;
    /**
     * can upload to or not
     */
    uploadable: boolean;
    /**
     * id of cloud-storage to connect
     */
    xStorage: string;
}

/**
 * 通常業務/転送先一括登録リクエスト
 */
export interface V1EndpointsCollectiveCreateRequest {
    /**
     * CSVファイル名
     */
    filename: string;
    /**
     * 通常業務/転送先の作成方法
     */
    importMode: ImportMode;
    /**
     * 通常業務/転送先の設定
     */
    settings: V1ObjectsEndpointsCollectiveSetting[];
    /**
     * workspacesの_id
     */
    workspace: string;
}

/**
 * 通常業務/転送先の作成方法
 */
export enum ImportMode {
    Append = "append",
    Recreate = "recreate",
}

/**
 * 通常業務/転送先設定
 */
export interface V1ObjectsEndpointsCollectiveSetting {
    /**
     * 半角英数記号（半角スペースを除く）
     */
    account: string;
    /**
     * 集約 (1,4)
     */
    aggregation: string;
    /**
     * 利用制限設定を有効化したときに許可するアプリケーションのリスト
     */
    application?: V1ObjectsClientApp[];
    /**
     * アプリケーションごとの通常業務利用制限設定
     * true(有効)、false(無効)
     */
    applicationPermissionSetting?: string;
    /**
     * 自動濃度
     */
    autoDensity: string;
    /**
     * フォルダ自動選択表示
     */
    autoSelect: string;
    /**
     * 大量原稿(大量原稿とSADFがともに[true]の場合はエラー)
     */
    batch: string;
    /**
     * white ～ dark-cyan の15種類
     */
    buttonColor: string;
    /**
     * 原稿種類(MONO_OCR, MONO_TEXT, MONO_MIXED, MONO_PHOTO, GRAYSCALE, FULLCOLOR_MIXED,
     * FULLCOLOR_PHOTO, ACS)
     * covasでは(0,1,2,3,4,5,6,7)
     */
    colorType: string;
    /**
     * 原稿種類変更ロック
     */
    colorTypeLock: string;
    /**
     * スプールの操作完了通知設定
     */
    completeNotification: string;
    /**
     * 印刷部数(1~999)
     */
    copies: string;
    /**
     * 日付(日)
     */
    dateFormatDay: string;
    /**
     * 日付(月)
     */
    dateFormatMonth: string;
    /**
     * 日付(時分秒)
     */
    dateFormatTime: string;
    /**
     * 日付(年)
     */
    dateFormatYear: string;
    /**
     * 日付セパレーター(., -, _, c, なし)
     * (cは漢字区切り)
     */
    dateSeparator?: string;
    /**
     * 濃度(2-8)
     */
    density: string;
    /**
     * 接続方法がGoogle共有ドライブの場合、共有ドライブ名の指定が必須
     */
    directory?: string;
    /**
     * 変更ロック
     */
    docnameFormatLock: string;
    /**
     * 原稿厚さ(薄め, 標準, 厚め)
     */
    documentThickness?: string;
    /**
     * 画像切出し
     * true(する)、false(しない)
     */
    doCutImage?: string;
    /**
     * ダウンロード可否
     */
    downloadable: string;
    /**
     * 半角/全角32byte
     */
    endpointName: string;
    /**
     * 32文字 半角英数記号
     */
    endpointPassword?: string;
    /**
     * 枠消去設定
     * 枠消去しない, 枠消去(同一幅), 枠消去(個別指定), センター消去, センター・枠消去(同一幅), センター・枠消去(個別指定)
     */
    eraseBorder?: string;
    /**
     * 枠消去(個別指定:下)サイズ(2~99)
     */
    eraseBorderWidthBottom?: string;
    /**
     * 枠消去(個別指定:左)サイズ(2~99)
     */
    eraseBorderWidthLeft?: string;
    /**
     * 枠消去(個別指定:右)サイズ(2~99)
     */
    eraseBorderWidthRight?: string;
    /**
     * 枠消去同一幅サイズ(2~99)
     */
    eraseBorderWidthSame?: string;
    /**
     * 枠消去(個別指定:上)サイズ(2~99)
     */
    eraseBorderWidthTop?: string;
    /**
     * センター消去サイズ(2~99)
     */
    eraseCenterWidth?: string;
    /**
     * スプールの操作失敗通知設定
     */
    errorNotification: string;
    /**
     * 任意文字未入力時継続読み取り
     */
    fileOptional: string;
    /**
     * 半角/全角※複数指定する場合は | (パイプライン)で繋ぐ
     */
    fixed?: string;
    /**
     * 1～4
     */
    folderDepth: string;
    /**
     * フォルダ表示形式(カラム,リスト)
     */
    folderDisplayFormat?: string;
    /**
     * フォルダーアイコン表示
     */
    folderIconVisible: string;
    /**
     * 32文字 半角/全角
     */
    folderTitle1?: string;
    /**
     * 32文字 半角/全角
     */
    folderTitle2?: string;
    /**
     * 32文字 半角/全角
     */
    folderTitle3?: string;
    /**
     * 32文字 半角/全角
     */
    folderTitle4?: string;
    /**
     * folder ～ gift の50種類
     */
    iconName: string;
    /**
     * 半角英数記号（半角スペースを除く）
     */
    invitationEmail: string;
    /**
     * %FolderN(Nは1から20までの数字),%Docname, %Date, %User, %InputStrN（Nは数字） の組み合わせ
     * covas上ではfolderN(Nは1から20までの数字),file,date,user,fixedN(Nは数字)に対応する
     */
    itemList: string;
    /**
     * 接続方法がMicrosoft 365 SharePoint Onlineの場合、必須
     */
    library?: string;
    /**
     * 印刷条件：変倍(NONE, B5, A4, B4, A3)
     */
    mediaSizeName: string;
    /**
     * 給紙トレイ(TRAY_AUTO, TRAY_MANUAL)
     */
    mediaTray: string;
    /**
     * MFPごとの通常業務利用制限設定
     * true(有効)、false(無効)
     */
    mfpPermissionSetting?: string;
    /**
     * 利用制限設定を有効化したときに許可するMFPの機械番号リスト
     * true(有効)、false(無効)
     */
    mfps?: string[];
    /**
     * サイズ混載
     */
    mixedSize: string;
    /**
     * サイズ混載モード
     * 同幅、異幅
     */
    mixedSizeMode?: string;
    /**
     * リスト表示列数(1, 2)
     */
    numberOfListDisplayColumns?: string;
    /**
     * 集約(NONE, TWO_IN_ONE, FOUR_IN_ONE)(0,2,4)
     */
    numberUp: string;
    /**
     * 白紙ページ除去
     */
    omitBlankPage?: string;
    /**
     * TIFF/JPG, PDF, COMPACT-PDF, MULTI-TIFF, MULTI-PDF, MULTICOMPACT-PDF
     * covasでは0,1,9,2,3,10に対応する
     */
    outputType: string;
    /**
     * 上書き(1),子番(0)
     */
    overwrite?: string;
    /**
     * 原稿面(片面, 両面(左右ひらき), 両面(左右開き),両面(上下ひらき), 両面(上下開き))
     * covasでは片面(clippedVertical: false, scanBothSide: false),
     * 両面(左右開き)(clippedVertical: true, scanBothSide: true),
     * 両面(上下開き)(clippedVertical: false, scanBothSide: true)に対応する
     */
    paperSide: string;
    /**
     * 原稿サイズ Auto, A3H, B4H, A4V, A4H, B5V, B5H, User0, User1, User2, CARDH
     * covasでは 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 14
     */
    paperSize: string;
    /**
     * 集約方向(Z,N)(0,1)
     */
    presentationDirection: string;
    /**
     * 不定形サイズ1横,最大範囲(128~999)範囲外は0に変換
     */
    preset1Horizontal: string;
    /**
     * 不定形サイズ1縦,最大範囲(128~999)範囲外は0に変換
     */
    preset1Vertical: string;
    /**
     * 不定形サイズ2横,最大範囲(128~999)範囲外は0に変換
     */
    preset2Horizontal: string;
    /**
     * 不定形サイズ2縦,最大範囲(128~999)範囲外は0に変換
     */
    preset2Vertical: string;
    /**
     * 不定形サイズ3横,最大範囲(128~999)範囲外は0に変換
     */
    preset3Horizontal: string;
    /**
     * 不定形サイズ3縦,最大範囲(128~999)範囲外は0に変換
     */
    preset3Vertical: string;
    /**
     * 印刷カラー条件(PRINT_MONO, PRINT_COLOR)
     */
    printColor: string;
    /**
     * 印刷対象(PDFのみ)
     */
    printDocumentFormat: string;
    /**
     * 印刷条件：パンチ(NONE, LEFT_TWO, TOP_TWO, RIGHT_TWO)
     */
    punch: string;
    /**
     * 解像度(200dpi, 300dpi, 400dpi, 600dpi)
     */
    resolution: string;
    /**
     * 解像度変更ロック
     */
    resolutionLock: string;
    /**
     * 原稿セット方向(left, top)
     */
    rotate: string;
    /**
     * SADF(大量原稿とSADFがともに[true]の場合はエラー)
     */
    sadf: string;
    /**
     * スキャン開始前の上書き警告
     */
    scanOverwriteAlert?: string;
    /**
     * スキャンプレビュー
     */
    scanPreview?: string;
    /**
     * ファイルの区切り文字(. or _ or - or 空文字)
     */
    separator: string;
    /**
     * 仕切り線
     */
    separatorLine: string;
    /**
     * 枝番,カウントアップ
     */
    sequenceType: string;
    /**
     * box, googledrive, googleteamdrive, dropbox, sharepointonline, onedrive, nonecloud
     */
    service: string;
    /**
     * 印刷条件：ソート(STACK, SORT_NORMAL, SORT_ROTATE, SORT_SHIFT)
     */
    sheetCollate: string;
    /**
     * 片面, 両面(左辺綴じ), 両面(上辺綴じ),週刊誌綴じ(左ひらき), 週刊誌綴じ(右ひらき)
     */
    sides: string;
    /**
     * 接続方法がMicrosoft 365 SharePoint Onlineの場合、必須
     */
    site?: string;
    /**
     * 印刷条件：ステープル(NONE, TOP_LEFT, TOP_LEFT_SLANT, TOP_RIGHT, TOP_RIGHT_SLANT, DUAL_TOP,
     * DUAL_LEFT, DUAL_RIGHT, SADDLE_STITCH)
     */
    staple: string;
    /**
     * アップロード可否
     */
    uploadable: string;
    /**
     * 半角/全角
     */
    wordList1?: string[];
    /**
     * 半角/全角
     */
    wordList2?: string[];
    /**
     * 32文字 半角/全角
     */
    wordListName1?: string;
    /**
     * 32文字 半角/全角
     */
    wordListName2?: string;
}

/**
 * クライアントアプリケーション
 */
export enum V1ObjectsClientApp {
    Easyfax = "easyfax",
    Logcapture = "logcapture",
    Quickscan = "quickscan",
    Smartecofax = "smartecofax",
    Speedoc = "speedoc",
}

/**
 * エンドポイント総数を取得する
 */
export interface V1EndpointsCollectiveExportCountCreateResponse {
    /**
     * 総件数
     */
    total: number;
}

/**
 * 通常業務/転送先エクスポートリクエスト
 */
export interface V1EndpointsCollectiveExportCreateRequest {
    /**
     * 取得件数の上限。必ずlimitまで取得されるとは限らない。
     */
    limit?: number;
    /**
     * 取得開始位置
     */
    offset?: number;
    /**
     * エクスポート対象のusers._id(指定がない場合はワークスペースユーザー全員が対象なのでタイムアウトに注意)
     */
    users?: string[];
    /**
     * 通常業務/転送先を取得するワークスペースID
     */
    workspace: string;
}

/**
 * 通常業務/転送先エクスポートレスポンス
 */
export interface V1EndpointsCollectiveExportCreateResponse {
    /**
     * 取得件数の上限
     */
    limit?: number;
    /**
     * 次の取得開始位置
     */
    offset?: number;
    /**
     * 通常業務/転送先の設定
     */
    settings: V1ObjectsEndpointsCollectiveSetting[];
    /**
     * レスポンスに含まれる通常業務/転送先の総件数
     */
    total: number;
}

/**
 * 通常業務/転送先を移行するリクエスト
 */
export interface V1EndpointsCollectiveMigrateCreateRequest {
    /**
     * CSVファイル名
     */
    filename: string;
    /**
     * 通常業務/転送先の作成方法
     */
    migrateImportMode: MigrateImportMode;
    /**
     * 通常業務/転送先の設定
     */
    settings: V1ObjectsEndpointsCollectiveSetting[];
    /**
     * workspacesの_id
     */
    workspace: string;
}

/**
 * 通常業務/転送先の作成方法
 */
export enum MigrateImportMode {
    Mode1 = "mode1",
    Mode2 = "mode2",
}

/**
 * update endpoint deatails request
 */
export interface V1EndpointsCreateCopyRequest {
    /**
     * Button color
     */
    buttonColor?: V1ObjectsEndpointsButtonColor;
    /**
     * endpoint_doc_names._id
     */
    docNameId?: string;
    /**
     * can download from or not
     */
    downloadable?: boolean;
    /**
     * Furigana for business card
     */
    furigana?: string;
    /**
     * name of icon to be display
     */
    iconName?: string;
    /**
     * endpoints._id
     */
    id?: string;
    /**
     * Setting of Email-endpoint-type
     */
    mailSetting?: V1ObjectsEndpointsMailSetting;
    /**
     * name of endpoint
     */
    name: string;
    /**
     * path to Endpoint (on cloud)
     */
    path?: string;
    /**
     * id of Endpoint (on cloud)
     */
    pathId?: string;
    /**
     * Flag send mail when upload/download process completed
     */
    serviceCompleted?: boolean;
    /**
     * Flag send mail when upload/download process error
     */
    serviceError?: boolean;
    /**
     * share type
     */
    share: number;
    /**
     * can upload to or not
     */
    uploadable?: boolean;
    /**
     * x_storages._id
     */
    xStorage?: string;
}

/**
 * Button color
 *
 * ラベル色
 *
 * ボタン色
 *
 * Endpoint Button color
 *
 * Endpoint's button color
 */
export enum V1ObjectsEndpointsButtonColor {
    Chocolate = "chocolate",
    CornflowerBlue = "cornflower-blue",
    Crimson = "crimson",
    Cyan = "cyan",
    DarkCyan = "dark-cyan",
    DarkGray = "dark-gray",
    DarkKhaki = "dark_khaki",
    HotPink = "hot-pink",
    LightGreen = "light-green",
    LightSalmon = "light-salmon",
    LimeGreen = "lime-green",
    MediumOrchid = "medium-orchid",
    OrangeRed = "orange-red",
    White = "white",
    Yellow = "yellow",
}

/**
 * Setting of Email-endpoint-type
 *
 * Mail setting object Email-endpoint-type
 *
 * エンドポイントのメール通知設定
 */
export interface V1ObjectsEndpointsMailSetting {
    /**
     * List of email to be sent to
     */
    address: string[];
    /**
     * Maximum size of a single file to be sent
     */
    fileMaxSize: number;
    /**
     * Subject to be inclueded in email
     */
    subject: string;
    /**
     * Email body template
     */
    template: string;
}

/**
 * Definition of create endpoint request.
 */
export interface V1EndpointsCreateRequest {
    /**
     * アプリケーション利用権限を割り当てるアプリケーション名
     */
    allowedApplications?: V1ObjectsClientApp[];
    /**
     * メンバー利用権限を割り当てるグループのid
     */
    allowedGroups?: string[];
    /**
     * 複合機利用権限を割り当てる複合機のid
     */
    allowedMfps?: string[];
    /**
     * メンバー利用権限を割り当てるユーザーのid
     */
    allowedUsers?: string[];
    docNameFormat: V1ObjectsEndpointsDefaultSettingDocNameFormat;
    endpoint:      Endpoint;
    /**
     * vocabulary
     */
    wordListData: string[];
    /**
     * vocabulary
     */
    wordListData02: string[];
}

export interface Endpoint {
    /**
     * List of email to be sent to
     */
    address?: string[];
    /**
     * アプリケーション利用制限設定を有効にするフラグ。`true`にした場合は、アプリケーションを有効にしないと通常業務を利用できない。
     */
    applicationPermissionSetting?: boolean;
    /**
     * ラベル色
     */
    buttonColor: V1ObjectsEndpointsButtonColor;
    /**
     * share to personal falg
     */
    copied?: boolean;
    /**
     * プリント機能可否
     */
    downloadable: boolean;
    /**
     * 通常業務パスワード
     */
    endpointPassword?: string;
    /**
     * Maximum attechment size
     */
    fileMaxSize?: number;
    /**
     * furigana for business card
     */
    furigana?: string;
    /**
     * 通常業務のアイコン
     */
    iconName: string;
    /**
     * 複合機利用制限設定を有効にするフラグ。`true`にした場合は、複合機を登録しないと通常業務を使用できない。
     */
    mfpPermissionSetting?: boolean;
    /**
     * 通常業務名
     */
    name: string;
    /**
     * path to Endpoint (on cloud)
     */
    path?: string;
    /**
     * id of Endpoint (on cloud)
     */
    pathId?: string;
    /**
     * Eメール通知:スプールの操作完了時
     */
    serviceCompleted?: boolean;
    /**
     * Eメール通知:スプールのエラー発生時
     */
    serviceError?: boolean;
    /**
     * share type
     */
    share: number;
    /**
     * Subject to be inclueded in email
     */
    subject?: string;
    /**
     * Email body template
     */
    template?: string;
    /**
     * スキャン機能/ファクス機能可否
     */
    uploadable: boolean;
    /**
     * メンバー利用制限設定を有効にするフラグ。`true`にした場合は、メンバーを登録しないと通常業務を使用できない。
     */
    userPermissionSetting?: boolean;
    /**
     * 接続方法 xstorages._id
     */
    xStorage: string;
    [property: string]: any;
}

/**
 * Definition of create endpoint response.
 */
export interface V1EndpointsCreateResponse {
    /**
     * Button color
     */
    buttonColor: V1ObjectsEndpointsButtonColor;
    /**
     * id of naming rule that link to endpoint
     */
    docNameId: string;
    /**
     * can download from or not
     */
    downloadable: boolean;
    /**
     * name of icon to be display
     */
    iconName: string;
    /**
     * created endpoint's id
     */
    id: string;
    /**
     * Setting of Email-endpoint-type
     */
    mailSetting?: V1ObjectsEndpointsMailSetting;
    /**
     * name of endpoint
     */
    name: string;
    /**
     * path to Endpoint (on cloud)
     */
    path?: string;
    /**
     * id of Endpoint (on cloud)
     */
    pathId?: string;
    /**
     * share type
     */
    share: number;
    /**
     * can upload to or not
     */
    uploadable: boolean;
    /**
     * id of cloud-storage to connect
     */
    xStorage: string;
}

/**
 * Endpoint default setting of endpoint
 */
export interface V1EndpointsDefaultSettingsShowResponse {
    /**
     * default setting array object
     */
    defaultSettings: V1ObjectsEndpointDefaultSetting;
    /**
     * endpoint data
     */
    endpoint: V1ObjectsEndpointInfo;
}

/**
 * default setting array object
 *
 * デフォルト設定
 */
export interface V1ObjectsEndpointDefaultSetting {
    /**
     * 新規作成日
     */
    createdAt?: string;
    /**
     * デフォルト設定の主キー
     */
    id?: string;
    /**
     * デフォルト設定の存在
     */
    isExistDefault?: boolean;
    /**
     * 複合機情報
     */
    mfpInfo?: V1ObjectsEndpointsDefaultSettingMfpInfo;
    /**
     * 基本設定
     */
    paramData?: V1ObjectsEndpointsDefaultSettingParamData;
    /**
     * PDFセキュリティ設定
     */
    pdfAttributeData: V1ObjectsEndpointsDefaultSettingPDFAttributeData;
    /**
     * プリント設定
     */
    printAttributeData: V1ObjectsEndpointsDefaultSettingPrintAttributeData;
    /**
     * スキャン設定
     */
    scanAttributeData: V1ObjectsEndpointsDefaultSettingScanAttributeData;
}

/**
 * 複合機情報
 *
 * Mfp Info Object
 *
 * Mfp information
 */
export interface V1ObjectsEndpointsDefaultSettingMfpInfo {
    /**
     * Location of MFP
     */
    deviceName?: string;
    /**
     * id of MFP
     */
    mfpId: string;
    /**
     * type of MFP
     */
    type?: string;
}

/**
 * 基本設定
 *
 * ParamData object
 *
 * Param data attribute
 */
export interface V1ObjectsEndpointsDefaultSettingParamData {
    /**
     * フォルダを自動選択する
     */
    autoSelect?: boolean;
    /**
     * フォルダ表示時の階層数
     */
    folderDepth?: number;
    /**
     * フォルダ表示形式
     */
    folderFormat?: number;
    /**
     * フォルダタイトル1
     */
    folderTitle1?: string;
    /**
     * フォルダタイトル2
     */
    folderTitle2?: string;
    /**
     * フォルダタイトル3
     */
    folderTitle3?: string;
    /**
     * フォルダタイトル4
     */
    folderTitle4?: string;
    /**
     * フォルダアイコンの表示
     */
    iconVisible?: boolean;
    /**
     * リスト表示列数(1, 2)
     */
    numberOfListDisplayColumns?: number;
    /**
     * ファイル上書き保存
     */
    overwrite?: number;
    /**
     * ｽｷｬﾝ時の上書き警告表示
     */
    scanOverwriteAlert?: boolean;
}

/**
 * PDFセキュリティ設定
 *
 * Pdf setting attribute object
 *
 * Pdf setting attribute
 */
export interface V1ObjectsEndpointsDefaultSettingPDFAttributeData {
    /**
     * 変更
     */
    allowEdit?: boolean;
    /**
     * コピー・抽出
     */
    allowExtract?: boolean;
    /**
     * 印刷
     */
    allowPrint?: number;
    /**
     * 権限設定有無
     */
    authorization?: boolean;
    /**
     * 権限パスワード
     */
    authorizationPassword?: string;
    /**
     * 暗号化可否
     */
    encrypt?: boolean;
    /**
     * 暗号化レベル
     */
    encryptLevel?: number;
    /**
     * 暗号化パスワード
     */
    encryptPassword?: string;
}

/**
 * プリント設定
 *
 * Print setting attribute object
 *
 * Print setting attribute
 */
export interface V1ObjectsEndpointsDefaultSettingPrintAttributeData {
    /**
     * カラー/白黒
     */
    color?: number;
    /**
     * 部数
     */
    copies?: number;
    /**
     * Word表示
     */
    displayDoc?: boolean;
    /**
     * PDF表示
     */
    displayPdf?: boolean;
    /**
     * PowerPoint表示
     */
    displayPpt?: boolean;
    /**
     * Excel表示
     */
    displayXls?: boolean;
    /**
     * 暗号化可否
     */
    encrypt?: boolean;
    /**
     * 用紙変倍サイズ
     */
    mediaSizeName?: number;
    /**
     * 給紙トレイ
     */
    mediaTray?: number;
    /**
     * 集約
     */
    numberUp?: number;
    /**
     * 集約方向
     */
    presentationDirection?: number;
    /**
     * パンチ
     */
    punch?: number;
    /**
     * 集約仕切り線
     */
    separatorLine?: boolean;
    /**
     * ソート
     */
    sheetCollate?: number;
    /**
     * 片面/両面
     */
    sides?: number;
    /**
     * ステープル
     */
    staple?: number;
}

/**
 * スキャン設定
 *
 * Scan setting attribute object
 *
 * Scan setting attribute
 */
export interface V1ObjectsEndpointsDefaultSettingScanAttributeData {
    /**
     * 集約可否
     */
    aggregation?: boolean;
    /**
     * 自動濃度するか否か
     */
    autoDensity?: boolean;
    /**
     * 大量原稿か否か
     */
    batch?: boolean;
    /**
     * とじ方向
     */
    clippedVertical?: boolean;
    /**
     * 原稿種類
     */
    colorType?: number;
    /**
     * 原稿種類の変更ロック
     */
    colorTypeLock?: boolean;
    /**
     * 濃度
     */
    density?: number;
    /**
     * 原稿厚さ(0(標準), 1(薄め), 2(厚め))
     */
    documentThickness?: number;
    /**
     * 画像切出し
     * true(する)、false(しない)
     */
    doCutImage?: boolean;
    /**
     * 枠消去するか否か
     */
    eraseBorder?: boolean;
    /**
     * 枠消去下幅
     */
    eraseBorderWidthBottom?: number;
    /**
     * 枠消去左幅
     */
    eraseBorderWidthLeft?: number;
    /**
     * 枠消去右幅
     */
    eraseBorderWidthRight?: number;
    /**
     * 枠消去同一幅
     */
    eraseBorderWidthSame?: number;
    /**
     * 枠消去上幅
     */
    eraseBorderWidthTop?: number;
    /**
     * センター消去するか否か
     */
    eraseCenter?: boolean;
    /**
     * センター消去幅
     */
    eraseCenterWidth?: number;
    /**
     * 枠消去同一幅設定するか
     */
    eraseSettingSame?: boolean;
    /**
     * サイズ混載か否か
     */
    mixedSize?: boolean;
    /**
     * サイズ混載モード
     * 0(同幅)、1(異幅)
     */
    mixedSizeMode?: number;
    /**
     * 白紙ページ削除
     */
    omitBlankPage?: boolean;
    /**
     * colorType lock from shared endpoint
     */
    orgColorTypeLock?: boolean;
    /**
     * resolution lock from shared endpoint
     */
    orgResolutionLock?: boolean;
    /**
     * 保存形式
     */
    outputType?: number;
    /**
     * 不定形サイズ１横
     */
    preset1Horizontal?: number;
    /**
     * 不定形サイズ１縦
     */
    preset1Vertical?: number;
    /**
     * 不定形サイズ２横
     */
    preset2Horizontal?: number;
    /**
     * 不定形サイズ２縦
     */
    preset2Vertical?: number;
    /**
     * 不定形サイズ３横
     */
    preset3Horizontal?: number;
    /**
     * 不定形サイズ３縦
     */
    preset3Vertical?: number;
    /**
     * 解像度
     */
    resolution?: number;
    /**
     * 解像度の変更ロック
     */
    resolutionLock?: boolean;
    /**
     * 原稿向き
     */
    rotate?: boolean;
    /**
     * SADFか否か
     */
    sadf?: boolean;
    /**
     * 両面スキャンをするか否か
     */
    scanBothSide?: boolean;
    /**
     * スキャン後にプレビューを表示するか?
     */
    scanPreview?: boolean;
    /**
     * 見開き原稿か否か
     */
    scanSpread?: boolean;
    /**
     * 原稿サイズ
     */
    size?: number;
}

/**
 * endpoint data
 *
 * エンドポイント情報
 */
export interface V1ObjectsEndpointInfo {
    /**
     * 利用可能なアプリケーション
     */
    applicationPermission?: V1ObjectsEndpointsPermissionsApplication[];
    /**
     * アプリケーションごとの利用制限設定の有効/無効
     */
    applicationPermissionSetting?: boolean;
    /**
     * ボタン色
     */
    buttonColor: V1ObjectsEndpointsButtonColor;
    /**
     * クラウドストレージアカウント
     */
    cloudAccount?: string;
    /**
     * Flag Copied from shared endpoint
     */
    copied?: boolean;
    /**
     * ダウンロード可能かどうか
     */
    downloadable: boolean;
    /**
     * endpoint password
     */
    endpointPassword?: string;
    /**
     * list print extension fail
     */
    extensionPrintFails?: string[];
    /**
     * list scan extension fail
     */
    extensionScanFails?: string[];
    /**
     * クラウドストレージ保存先
     */
    folderPath?: string;
    /**
     * クラウドストレージのフォルダID
     */
    folderPathId?: string;
    /**
     * グループごとの利用制限設定
     */
    groupPermission?: V1ObjectsEndpointsPermissionsGroup[];
    /**
     * アイコン名
     */
    iconName: string;
    /**
     * エンドポイントの主キー
     */
    id: string;
    /**
     * 無効かどうか
     */
    isDisable?: boolean;
    /**
     * Workspace downloadable flag
     */
    isStorageAvailable?: boolean;
    /**
     * Setting of Email-endpoint-type
     */
    mailSetting?: V1ObjectsEndpointsMailSetting;
    /**
     * Metadata info
     */
    metadata?: { [key: string]: any };
    /**
     * 複合機ごとの利用制限設定
     */
    mfpPermission?: V1ObjectsEndpointsPermissionsMfp[];
    /**
     * 複合機ごとの利用制限設定の有効/無効
     */
    mfpPermissionSetting?: boolean;
    /**
     * エンドポイントの名前
     */
    name: string;
    /**
     * Order of endpoint when displaying
     */
    order?: number;
    /**
     * Flag send mail when upload/download process completed
     */
    serviceCompleted?: boolean;
    /**
     * Flag send mail when upload/download process error
     */
    serviceError?: boolean;
    /**
     * shared endpoint or individual endpoint
     */
    share?: number;
    /**
     * クラウドストレージ名
     */
    type: V1ObjectsServiceEnum;
    /**
     * アップロード可能かどうか
     */
    uploadable: boolean;
    /**
     * ユーザーごとの利用制限設定
     */
    userPermission?: V1ObjectsEndpointsPermissionsUser[];
    /**
     * ユーザーごとの利用制限設定の有効/無効
     */
    userPermissionSetting?: boolean;
    /**
     * ワークスペース設定のダウンロード可否
     */
    wsDownloadable?: boolean;
    /**
     * ワークスペース設定のアップロード可否
     */
    wsUploadable?: boolean;
    /**
     * クラウドストレージ主キー
     */
    xStorageId?: string;
}

/**
 * 通常業務のアプリケーションごとの利用制限設定
 */
export interface V1ObjectsEndpointsPermissionsApplication {
    application?: V1ObjectsClientApp;
    /**
     * endpoint_application_permissions._id
     */
    id: string;
}

/**
 * 通常業務のユーザーごとの利用制限設定
 */
export interface V1ObjectsEndpointsPermissionsGroup {
    /**
     * グループ
     */
    group?: V1ObjectsGroup;
    /**
     * endpoint_user_permissions._id
     */
    id: string;
}

/**
 * グループ
 *
 * V1ObjectsGroup
 */
export interface V1ObjectsGroup {
    /**
     * グループアイコンURL
     */
    avatarUrl?: string;
    /**
     * グループ名
     */
    groupName?: string;
    /**
     * グループID
     */
    id?: string;
    /**
     * グループメンバー数
     */
    memberCount?: number;
    /**
     * ワークスペースID
     */
    workspace?: string;
}

/**
 * 通常業務の複合機ごとの利用制限設定
 */
export interface V1ObjectsEndpointsPermissionsMfp {
    /**
     * endpoint_mfp_permissions._id
     */
    id: string;
    /**
     * ワークスペース複合機
     */
    mfp?: V1ObjectsWorkspacemfp;
}

/**
 * ワークスペース複合機
 *
 * workspace mfp info object
 */
export interface V1ObjectsWorkspacemfp {
    /**
     * name by administrator
     */
    deviceName: string;
    /**
     * worksapce-mfp id
     */
    id: string;
    /**
     * number of user has registered to use this mfp
     */
    memberCount?: number;
    /**
     * mfp location
     */
    mfpLocation: string;
    /**
     * mfp Name
     */
    mfpName: string;
    /**
     * mfp number
     */
    mfpNumber: string;
    /**
     * State
     */
    status?: V1ObjectsWorkspacemfpStatus;
}

/**
 * State
 *
 * status of mfps
 *
 * status of mfp
 */
export enum V1ObjectsWorkspacemfpStatus {
    Active = "active",
    Inactive = "inactive",
}

/**
 * クラウドストレージ名
 *
 * Enum of cloud-services
 *
 * 最後に認可発行したアプリの記録.Googleドライブアプリの統合に使用。
 *
 * クラウド種別
 *
 * service Name
 *
 * client type
 */
export enum V1ObjectsServiceEnum {
    Box = "box",
    Docab = "docab",
    Docard = "docard",
    Dropbox = "dropbox",
    Email = "email",
    Googledrive = "googledrive",
    Googleteamdrive = "googleteamdrive",
    Nonecloud = "nonecloud",
    Onedrive = "onedrive",
    Sharepointonline = "sharepointonline",
}

/**
 * 通常業務のユーザーごとの利用制限設定
 */
export interface V1ObjectsEndpointsPermissionsUser {
    /**
     * endpoint_user_permissions._id
     */
    id: string;
    /**
     * ワークスペースユーザー
     */
    user?: V1ObjectsWorkspaceuserMedium;
}

/**
 * ワークスペースユーザー
 */
export interface V1ObjectsWorkspaceuserMedium {
    /**
     * アカウント停止フラグ
     */
    active: boolean;
    /**
     * アバター画像URL
     */
    avatarUrl: string;
    /**
     * 連絡先メールアドレス
     */
    contactEmail: string;
    /**
     * workspace_users._id
     */
    id: string;
    /**
     * 招待メールアドレス
     */
    invitationEmail: string;
    /**
     * 言語
     */
    language: Language;
    /**
     * ユーザー名
     */
    name: string;
    /**
     * ユーザー名（フリガナ）
     */
    phoneticName: string;
    /**
     * ロール
     */
    role: number;
}

/**
 * 言語
 *
 * workspace user language
 *
 * workspace language
 *
 * ワークスペース言語
 */
export enum Language {
    En = "en",
    Ja = "ja",
}

/**
 * Endpoint default setting update request
 */
export interface V1EndpointsDefaultSettingsUpdateRequest {
    /**
     * created time
     */
    createdAt?: string;
    /**
     * endpoint password
     */
    endpointPassword?: string;
    /**
     * defaultSetting id
     */
    id?: string;
    /**
     * is exist default setting flag
     */
    isExistDefault?: boolean;
    /**
     * Mfp information
     */
    mfpInfo?: V1ObjectsEndpointsDefaultSettingMfpInfo;
    /**
     * Param data attribute
     */
    paramData: V1ObjectsEndpointsDefaultSettingParamData;
    /**
     * Pdf setting attribute
     */
    pdfAttributeData: V1ObjectsEndpointsDefaultSettingPDFAttributeData;
    /**
     * Print setting attribute
     */
    printAttributeData: V1ObjectsEndpointsDefaultSettingPrintAttributeData;
    /**
     * Scan setting attribute
     */
    scanAttributeData: V1ObjectsEndpointsDefaultSettingScanAttributeData;
}

/**
 * Definition of delete endpoint response.
 */
export interface V1EndpointsDestroyResponse {
    /**
     * id of endpoint
     */
    id: string;
}

/**
 * show endpoint deatails response
 */
export interface V1EndpointsDetailsShowResponse {
    /**
     * Button color
     */
    buttonColor: V1ObjectsEndpointsButtonColor;
    /**
     * can download from or not
     */
    downloadable: boolean;
    /**
     * name of icon to be display
     */
    iconName: string;
    /**
     * created endpoint's id
     */
    id: string;
    /**
     * Setting of Email-endpoint-type
     */
    mailSetting?: V1ObjectsEndpointsMailSetting;
    /**
     * name of endpoint
     */
    name: string;
    /**
     * path to Endpoint (on cloud)
     */
    path?: string;
    /**
     * id of Endpoint (on cloud)
     */
    pathId?: string;
    /**
     * Flag send mail when upload/download process completed
     */
    serviceCompleted: boolean;
    /**
     * Flag send mail when upload/download process error
     */
    serviceError: boolean;
    /**
     * can upload to or not
     */
    uploadable: boolean;
    /**
     * id of cloud-storage to connect
     */
    xStorage: string;
}

/**
 * update endpoint deatails request
 */
export interface V1EndpointsDetailsUpdateRequest {
    /**
     * アプリケーション利用権限を割り当てるアプリケーション名
     */
    allowedApplications?: V1ObjectsClientApp[];
    /**
     * メンバー利用権限を割り当てるグループのid
     */
    allowedGroups?: string[];
    /**
     * 複合機利用権限を割り当てる複合機のid
     */
    allowedMfps?: string[];
    /**
     * メンバー利用権限を割り当てるユーザーのid
     */
    allowedUsers?: string[];
    /**
     * アプリケーションごとの利用制限設定の有効/無効
     */
    applicationPermissionSetting?: boolean;
    /**
     * Button color
     */
    buttonColor: V1ObjectsEndpointsButtonColor;
    /**
     * can download from or not
     */
    downloadable: boolean;
    /**
     * Furigana for business card
     */
    furigana?: string;
    /**
     * name of icon to be display
     */
    iconName: string;
    /**
     * Setting of Email-endpoint-type
     */
    mailSetting?: V1ObjectsEndpointsMailSetting;
    /**
     * MFPごとの利用制限設定の有効/無効
     */
    mfpPermissionSetting?: boolean;
    /**
     * name of endpoint
     */
    name: string;
    /**
     * path to Endpoint (on cloud)
     */
    path: string;
    /**
     * id of Endpoint (on cloud)
     */
    pathId?: string;
    /**
     * Flag send mail when upload/download process completed
     */
    serviceCompleted: boolean;
    /**
     * Flag send mail when upload/download process error
     */
    serviceError: boolean;
    /**
     * can upload to or not
     */
    uploadable: boolean;
    /**
     * ユーザごとの利用制限設定の有効/無効
     */
    userPermissionSetting?: boolean;
}

/**
 * update endpoint deatails response
 */
export interface V1EndpointsDetailsUpdateResponse {
    /**
     * 利用可能なアプリケーション
     */
    applicationPermission?: V1ObjectsEndpointsPermissionsApplication[];
    /**
     * アプリケーションごとの利用制限設定の有効/無効
     */
    applicationPermissionSetting?: boolean;
    /**
     * Button color
     */
    buttonColor: V1ObjectsEndpointsButtonColor;
    /**
     * can download from or not
     */
    downloadable: boolean;
    /**
     * Furigana for business card
     */
    furigana?: string;
    /**
     * グループごとの利用制限設定
     */
    groupPermission?: V1ObjectsEndpointsPermissionsGroup[];
    /**
     * name of icon to be display
     */
    iconName: string;
    /**
     * created endpoint's id
     */
    id: string;
    /**
     * Setting of Email-endpoint-type
     */
    mailSetting?: V1ObjectsEndpointsMailSetting;
    /**
     * 複合機ごとの利用制限設定
     */
    mfpPermission?: V1ObjectsEndpointsPermissionsMfp[];
    /**
     * MFPごとの利用制限設定の有効/無効
     */
    mfpPermissionSetting?: boolean;
    /**
     * name of endpoint
     */
    name: string;
    /**
     * path to Endpoint (on cloud)
     */
    path?: string;
    /**
     * id of Endpoint (on cloud)
     */
    pathId?: string;
    /**
     * Flag send mail when upload/download process completed
     */
    serviceCompleted: boolean;
    /**
     * Flag send mail when upload/download process error
     */
    serviceError: boolean;
    /**
     * can upload to or not
     */
    uploadable: boolean;
    /**
     * ユーザーごとの利用制限設定
     */
    userPermission?: V1ObjectsEndpointsPermissionsUser[];
    /**
     * ユーザごとの利用制限設定の有効/無効
     */
    userPermissionSetting?: boolean;
    /**
     * id of cloud-storage to connect
     */
    xStorage: string;
}

/**
 * File infomation
 */
export interface V1EndpointsFilesInfoShowResponse {
    /**
     * time file is uploaded
     */
    createDate: string;
    /**
     * File description
     */
    description?: string;
    /**
     * file/folder id
     */
    id?: string;
    /**
     * last time modified
     */
    lastUpdated: string;
    /**
     * File name in
     */
    name: string;
    /**
     * File size in byte
     */
    size: number;
    [property: string]: any;
}

/**
 * フォルダ一覧又は、ファイル一覧取得リクエスト
 */
export interface V1EndpointsFilesShowRequest {
    /**
     * 前回の続きを取得する場合のみ指定
     */
    cursor?: string;
    /**
     * 親フォルダーのId or Path(フルパス)
     */
    folder?: string;
    /**
     * ファイル一覧かフォルダー一覧
     */
    itemType?: ItemType;
    /**
     * 一度に取得する最大件数
     */
    limit?: string;
}

/**
 * ファイル一覧かフォルダー一覧
 *
 * file, folder, cabine, user
 */
export enum ItemType {
    Cabinet = "cabinet",
    File = "file",
    Folder = "folder",
    User = "user",
}

/**
 * Endpoint file response of tenant user
 */
export interface V1EndpointsFilesShowResponse {
    /**
     * 現在カーソル
     */
    cursor?: string;
    /**
     * エンドポイントファイルリスト
     */
    endpointFiles: V1ObjectsEndpointFile[];
    /**
     * 親フォルダーのId or Path(フルパス)
     */
    folderPath: string;
    /**
     * 一度に取得する最大件数
     */
    limit?: number;
    /**
     * フォルダ階層ごとにフォルダ名を格納する
     */
    pathArray?: V1ObjectsXstorageFile[];
    [property: string]: any;
}

/**
 * フォルダ内のファイル・フォルダのメタデータ。
 * サービスによってプロパティが追加されてる可能性があるので、additionalProperties: true にしてください。
 */
export interface V1ObjectsEndpointFile {
    /**
     * 名刺系の場合のみ返却される
     */
    furigana?: string;
    /**
     * file/folder id
     */
    id: string;
    /**
     * file, folder, cabine, user
     */
    itemType: ItemType;
    /**
     * file/folder 名前
     */
    name: string;
    /**
     * アイテムパス
     */
    path: string;
    /**
     * 書き込み権限
     */
    writable: boolean;
    [property: string]: any;
}

/**
 * file/folder metadata in folder
 */
export interface V1ObjectsXstorageFile {
    /**
     * file/folder id
     */
    id: string;
    /**
     * file/folder name in folder
     */
    name: string;
    /**
     * path to item
     */
    path: string;
    /**
     * Permission to upload
     */
    writable: boolean;
}

/**
 * エンドポイント一覧取得リクエスト
 */
export interface V1EndpointsIndexResponse {
    /**
     * Endpoint list
     */
    endpoints: V1ObjectsEndpointInfo[];
    /**
     * pagination
     */
    pagination?: V1ObjectsPagination;
}

/**
 * pagination
 *
 * Pagination Info Object
 *
 * ページネーション
 */
export interface V1ObjectsPagination {
    /**
     * リミット
     */
    limit?: number;
    /**
     * オフセット
     */
    offset?: number;
    /**
     * リストの総数
     */
    totalCount?: number;
}

/**
 * Mail setting show response
 */
export interface V1EndpointsMailSettingShowResponse {
    /**
     * List of email to be sent to
     */
    address: string[];
    /**
     * Maximum size of a single file to be sent
     */
    fileMaxSize: number;
    /**
     * Subject to be inclueded in email
     */
    subject: string;
    /**
     * Email body template
     */
    template: string;
    [property: string]: any;
}

/**
 * Patch request to update order of endpoints
 */
export interface V1EndpointsOrdersPatchRequest {
    /**
     * Endpoint's order list
     */
    endpoints: V1ObjectsEndpointOrder[];
}

/**
 * Endpoints order Information object
 */
export interface V1ObjectsEndpointOrder {
    /**
     * Endpoint Id
     */
    id: string;
    /**
     * Order of endpoint when displaying
     */
    order: number;
}

/**
 * Definition of v1-endpoints-patch-response.
 */
export interface V1EndpointsOrdersPatchResponse {
    status: V1ObjectsModifyResponseStatusEnum;
}

/**
 * Enum of modify-response
 */
export enum V1ObjectsModifyResponseStatusEnum {
    Accepted = "accepted",
    Denied = "denied",
}

/**
 * show endpoint-naming-rules response
 */
export interface V1EndpointsRulesShowResponse {
    /**
     * date format
     */
    dateFormat: string;
    /**
     * 日付セパレータ
     */
    dateSeparator?: string;
    /**
     * Endpoint's id
     */
    endpoint: string;
    /**
     * Can upload even if user does not enter any characters
     */
    fileOptional: boolean;
    /**
     * fixed item data
     */
    fixed: string[];
    /**
     * Flag Copied from Shared Endpoint
     */
    isCopied?: boolean;
    /**
     * format of file name
     */
    itemList: string;
    /**
     * Switch lock edit
     */
    lockEdit: boolean;
    /**
     * lock edit from shared Endpoint
     */
    orgLockEdit?: boolean;
    /**
     * separator between part of itemList
     */
    separator: string;
    /**
     * Sequence type
     */
    sequenceType: number;
    /**
     * vocabulary
     */
    wordList: string[];
    /**
     * vocabulary
     */
    wordList02: string[];
    /**
     * 単語帳名
     */
    wordListName01: string;
    /**
     * 単語帳名
     */
    wordListName02: string;
}

/**
 * update endpoint-naming-rules request
 */
export interface V1EndpointsRulesUpdateRequest {
    /**
     * date format
     */
    dateFormat: string;
    /**
     * 日付セパレーター(., -, _, c, ""(空文字))
     * (cは漢字区切り)
     * 空文字は「なし」のパラメータとして扱う
     */
    dateSeparator?: string;
    /**
     * Can upload even if user does not enter any characters
     */
    fileOptional: boolean;
    /**
     * fixed item data
     */
    fixed: string[];
    /**
     * format of file name
     */
    itemList: string;
    /**
     * Switch lock edit
     */
    lockEdit: boolean;
    /**
     * separator between part of itemList
     */
    separator: string;
    /**
     * Sequence type
     */
    sequenceType: number;
    /**
     * vocabulary
     */
    wordList: string[];
    /**
     * vocabulary
     */
    wordList02: string[];
    /**
     * 単語帳名
     */
    wordListName01: string;
    /**
     * 単語帳名
     */
    wordListName02: string;
}

/**
 * show endpoint-naming-rules response
 */
export interface V1EndpointsRulesUpdateResponse {
    /**
     * date format
     */
    dateFormat: string;
    /**
     * 日付セパレーター(., -, _, c, ""(空文字))
     * (cは漢字区切り)
     * 空文字は「なし」のパラメータとして扱う
     */
    dateSeparator?: string;
    /**
     * Endpoint's id
     */
    endpoint: string;
    /**
     * Can upload even if user does not enter any characters
     */
    fileOptional: boolean;
    /**
     * fixed item data
     */
    fixed: string[];
    /**
     * Flag Copied from Shared Endpoint
     */
    isCopied?: boolean;
    /**
     * format of file name
     */
    itemList: string;
    /**
     * Switch lock
     */
    lockEdit: boolean;
    /**
     * Switch lock from Shared Endpoint
     */
    orgLockEdit?: boolean;
    /**
     * separator between part of itemList
     */
    separator: string;
    /**
     * Sequence type
     */
    sequenceType: number;
    /**
     * vocabulary
     */
    wordList: string[];
    /**
     * vocabulary
     */
    wordList02: string[];
    /**
     * 単語帳名
     */
    wordListName01: string;
    /**
     * 単語帳名
     */
    wordListName02: string;
}

/**
 * エンドポイント更新結果
 */
export interface V1EndpointsStatusShowResponse {
    /**
     * 結果ステータス
     */
    status: V1EndpointsStatusShowResponseStatus;
}

/**
 * 結果ステータス
 *
 * accepted | completed
 *
 * 更新結果
 *
 * status of update mfp info
 */
export enum V1EndpointsStatusShowResponseStatus {
    Accepted = "accepted",
    Completed = "completed",
}

/**
 * 通常業務のメール配信停止リクエスト
 */
export interface V1EndpointsUnsubscribeCreateResponse {
    /**
     * 通常業務ID
     */
    endpointId: string;
    /**
     * 通常業務のメール配信停止タイプ
     */
    endpointMailType: EndpointMailType;
    /**
     * 通常業務名
     */
    endpointName: string;
    /**
     * 通常業務(共有)かどうか
     */
    isShared: boolean;
}

/**
 * 通常業務のメール配信停止タイプ
 */
export enum EndpointMailType {
    ServiceComplete = "serviceComplete",
    ServiceError = "serviceError",
}

/**
 * エンドポイント更新リクエスト
 */
export interface V1EndpointsUpdateRequest {
    /**
     * デフォルト設定
     */
    defaultSettings: V1ObjectsEndpointDefaultSetting[];
    /**
     * ファイル名形式
     */
    docNameFormat: V1ObjectsEndpointsDefaultSettingDocNameFormat;
    /**
     * エンドポイント情報
     */
    endpoint?: V1ObjectsEndpointInfo;
    /**
     * 単語帳1
     */
    wordListData?: string[];
    /**
     * 単語帳2
     */
    wordListData02?: string[];
}

/**
 * エンドポイントの詳細情報レスポンス
 */
export interface V1EndpointsUpdateResponse {
    /**
     * デフォルト設定
     */
    defaultSettings: V1ObjectsEndpointDefaultSetting[];
    /**
     * ファイル名形式
     */
    docNameFormat: V1ObjectsEndpointsDefaultSettingDocNameFormat;
    /**
     * エンドポイント情報
     */
    endpoint: V1ObjectsEndpointInfo;
    /**
     * エンドポイントのメール通知設定
     */
    mailSetting?: V1ObjectsEndpointsMailSetting;
    /**
     * 単語帳1
     */
    wordListData: string[];
    /**
     * 単語帳2
     */
    wordListData02: string[];
    /**
     * 単語帳名1
     */
    wordListName01: string;
    /**
     * 単語帳名2
     */
    wordListName02: string;
}

/**
 * create entry data
 */
export interface V1EntryCreateRequest {
    /**
     * 申し込み区分
     */
    category: string;
    /**
     * お客様企業名
     */
    clientCompany: string;
    /**
     * お客様部署名
     */
    clientDepartment: string;
    /**
     * 担当者-名
     */
    clientFirstName: string;
    /**
     * 担当者-姓
     */
    clientLastName: string;
    /**
     * メールアドレス
     */
    clientMail: string;
    /**
     * 電話番号
     */
    clientPhone: string;
    /**
     * 申し込みクラウド
     */
    cloud: V1ObjectsEntryCloud;
    /**
     * 大塚商会担当エンジニア-名
     */
    otsEngineerFirstName: string;
    /**
     * 大塚商会担当エンジニア-姓
     */
    otsEngineerLastName: string;
    /**
     * 大塚商会担当エンジニアメールアドレス
     */
    otsEngineerMail: string;
    /**
     * 大塚商会担当営業部署名
     */
    otsSalesDepartment: string;
    /**
     * 大塚商会担当営業-名
     */
    otsSalesFirstName: string;
    /**
     * 大塚商会担当営業-姓
     */
    otsSalesLastName: string;
    /**
     * 大塚商会担当営業メールアドレス
     */
    otsSalesMail: string;
    /**
     * 大塚商会担当営業電話番号
     */
    otsSalesPhone: string;
    products:      V1ObjectsEntryProducts;
}

/**
 * 申し込みクラウド
 *
 * クラウド
 */
export interface V1ObjectsEntryCloud {
    /**
     * box
     */
    box: boolean;
    /**
     * dropbox
     */
    dropbox: boolean;
    /**
     * googleDrive
     */
    googleDrive: boolean;
    /**
     * googleShareDrive
     */
    googleShareDrive: boolean;
    /**
     * oneDrive
     */
    oneDrive: boolean;
    /**
     * sharePoint
     */
    sharePoint: boolean;
}

/**
 * 製品名
 *
 * 申し込み製品
 */
export interface V1ObjectsEntryProducts {
    /**
     * Easyファクス
     */
    easyFax: boolean;
    /**
     * Quickスキャン
     */
    qs: boolean;
}

/**
 * create entry data
 */
export interface V1EntryCreateResponse {
    /**
     * 申し込み番号
     */
    entryNumber: string;
}

/**
 * 解約取り消しリクエスト
 */
export interface V1EntryPatchCancelRequest {
    /**
     * id of entry model
     */
    entryId: string;
    /**
     * 申込番号
     */
    entryNumber: string;
}

/**
 * show entry data list
 */
export interface V1EntryShowListRequest {
    /**
     * お客様企業名
     */
    clientCompany?: string;
    /**
     * メールアドレス
     */
    clientMail?: string;
    /**
     * 担当者名
     */
    clientName?: string;
    /**
     * 申し込み番号
     */
    entryNumber?: string;
    /**
     * 期間始まり日本時間のまま
     */
    from?: string;
    /**
     * 取得数
     */
    limit: number;
    /**
     * DBの取得開始位置
     */
    offset: number;
    /**
     * ソート項目
     */
    sortType: V1ObjectsEntrySortType;
    /**
     * 期間終わり日本時間のまま
     */
    to?: string;
    /**
     * 表示タブ
     */
    viewTab: V1ObjectsEntryState;
}

/**
 * ソート項目
 *
 * 申込ソートタイプ
 */
export interface V1ObjectsEntrySortType {
    /**
     * 日付ソート
     */
    date: V1ObjectsSort;
    /**
     * 申込番号ソート
     */
    entryNumber: V1ObjectsSort;
}

/**
 * 日付ソート
 *
 * 昇順降順指定
 *
 * 申込番号ソート
 */
export enum V1ObjectsSort {
    Asc = "asc",
    Desc = "desc",
    None = "none",
}

/**
 * 表示タブ
 *
 * Workspace Invite Mail Status
 *
 * 申し込み状況
 */
export enum V1ObjectsEntryState {
    Cancel = "cancel",
    Complete = "complete",
    Create = "create",
    Delete = "delete",
    Entry = "entry",
}

/**
 * show entry data list
 */
export interface V1EntryShowListResponse {
    /**
     * 申込情報の一覧
     */
    entries: V1ObjectsEntryListIndex[];
    /**
     * 申込の総件数
     */
    maxNumber: number;
}

/**
 * show entry data list
 */
export interface V1ObjectsEntryListIndex {
    /**
     * 解約日
     */
    cancelDate?: string;
    /**
     * 申し込み区分
     */
    category: string;
    /**
     * お客様企業名
     */
    clientCompany: string;
    /**
     * 担当者-名
     */
    clientFirstName: string;
    /**
     * 担当者-姓
     */
    clientLastName: string;
    /**
     * メールアドレス
     */
    clientMail: string;
    /**
     * 申し込み日
     */
    createdAt?: string;
    /**
     * 削除日
     */
    deleteDate?: string;
    /**
     * 申し込み番号
     */
    entryNumber: string;
    /**
     * 申し込み状況
     */
    entryState: V1ObjectsEntryState;
    /**
     * id of entry model
     */
    id: string;
}

/**
 * 解約リクエスト
 */
export interface V1EntryUpdateCancelRequest {
    /**
     * id of entry model
     */
    entryId: string;
    /**
     * 申込番号
     */
    entryNumber: string;
}

/**
 * update entry data
 */
export interface V1EntryUpdateRequest {
    /**
     * 申し込み区分
     */
    category: string;
    /**
     * お客様企業名
     */
    clientCompany: string;
    /**
     * お客様部署名
     */
    clientDepartment: string;
    /**
     * 担当者-名
     */
    clientFirstName: string;
    /**
     * 担当者-姓
     */
    clientLastName: string;
    /**
     * メールアドレス
     */
    clientMail: string;
    /**
     * 電話番号
     */
    clientPhone: string;
    /**
     * 申し込みクラウド
     */
    cloud: V1ObjectsEntryCloud;
    /**
     * 申し込み番号
     */
    entryNumber: string;
    /**
     * 申し込み状況
     */
    entryState: V1ObjectsEntryState;
    /**
     * id of entry model
     */
    id: string;
    /**
     * 大塚商会担当エンジニア-名
     */
    otsEngineerFirstName: string;
    /**
     * 大塚商会担当エンジニア-姓
     */
    otsEngineerLastName: string;
    /**
     * 大塚商会担当エンジニアメールアドレス
     */
    otsEngineerMail: string;
    /**
     * 大塚商会担当営業部署名
     */
    otsSalesDepartment: string;
    /**
     * 大塚商会担当営業-名
     */
    otsSalesFirstName: string;
    /**
     * 大塚商会担当営業-姓
     */
    otsSalesLastName: string;
    /**
     * 大塚商会担当営業メールアドレス
     */
    otsSalesMail: string;
    /**
     * 大塚商会担当営業電話番号
     */
    otsSalesPhone: string;
    /**
     * 申し込み製品
     */
    products: V1ObjectsEntryProducts;
}

/**
 * エラーレスポンス
 */
export interface V1ErrorsErrorResponse {
    /**
     * error object
     */
    error: Error;
    /**
     * エラーのサマリー
     */
    error_summary: string;
}

/**
 * error object
 */
export interface Error {
    /**
     * エラーコード
     */
    code: string;
    /**
     * デバッグメッセージ(開発用)
     */
    debugMessage?: string;
    /**
     * エラーID
     */
    errorId?: string;
    /**
     * エラーメッセージ(日本語)
     */
    message: string;
    /**
     * エラーが発生したリクエストId
     */
    requestId: string;
    /**
     * エラーが発生したリソースId(どこキャビ用)
     */
    resource: string;
    [property: string]: any;
}

/**
 * Definition of create event request.
 */
export interface V1EventsCreateRequest {
    /**
     * Action is a definition of event.
     * The format is strictly regulated, and it must consist of three segments concatenated by
     * periods (.).
     * Segment only accepts lowercase alphabet with optional dash (-).
     *
     * e.g.
     * - Good) some.action.name
     * - Good) so-me.act-ion.na-me
     * - Bad)  somE.acTion.Name
     * - Bad)  some1.action2.name3
     */
    action: string;
    client: Client;
    /**
     * Metadata takes arbitary object for further reference, however be careful not to store
     * meaningless data, because it will bloat datalake storage and slows down analytics.
     * Arbitary object is also very difficult to query correctly with SQL (USQL), so it may be
     * excluded from the standard statistics workflow.
     */
    metadata?: { [key: string]: any };
    /**
     * Set true when this object represents a successful event, otherwise false
     */
    ok: boolean;
    /**
     * Specify caller package name
     */
    package: Package;
    /**
     * For webapp, set revision (Git Revision) given as environment variable.
     * For MFP, set application version (e.g. 5.00)
     */
    revision: string;
}

export interface Client {
    /**
     * Browser name and version for webapp, or something identifiable for MFP.
     */
    browser: string;
    /**
     * Specify either client IP address (IPv4/IPv6) or MFP device.
     */
    from: string;
    /**
     * True on MFP, false on others (e.g. webapp)
     */
    isMfp: boolean;
    /**
     * Platform (OS) name.
     * React apps are recommended to use OS detection library such as,
     * https://github.com/KennethanCeyer/browser-detect
     */
    platform: string;
    [property: string]: any;
}

/**
 * Specify caller package name
 */
export enum Package {
    Mfp = "mfp",
    WebappAdmin = "webapp-admin",
    WebappUser = "webapp-user",
}

/**
 * Definition of create endpoint response.
 */
export interface V1EventsCreateResponse {
    /**
     * Tells the request is accepted or not
     */
    accepted: boolean;
}

/**
 * show mail status
 */
export interface V1MailShowRequest {
    /**
     * email
     */
    email: string;
    /**
     * workspace id
     */
    workspaceId: string;
}

/**
 * show mail status
 */
export interface V1MailShowResponse {
    /**
     * mail status
     */
    status: V1ObjectsMailEvent;
}

/**
 * mail status
 *
 * Workspace Invite Mail Status
 */
export enum V1ObjectsMailEvent {
    Bounce = "bounce",
    Click = "click",
    Deferred = "deferred",
    Delivered = "delivered",
    Dropped = "dropped",
    Open = "open",
    Processed = "processed",
    Spamreport = "spamreport",
    Unsent = "unsent",
}

/**
 * 複合機PIN認証リクエスト
 */
export interface V1MfpAuthCreateRequest {
    /**
     * ログインユーザ名（共有デバイスのユーザと紐づくユーザ名を設定）※PINを送信しない場合は、設定必須
     */
    deviceLoginUser?: string;
    /**
     * Covasユーザのメールアドレス
     */
    email: string;
    /**
     * 複合機の機械番号(暗号化した内容を設定)
     */
    mfpNumber: string;
    /**
     * 数字6桁 ※PINが省略可能なら未設定の場合あり
     */
    pin?: string;
}

/**
 * 複合機PIN認証リクエスト
 */
export interface V1MfpAuthCreateResponse {
    /**
     * Covas接続用のトークン（トークン内部に機械番号など含む）
     */
    authToken: string;
}

/**
 * Definition of create Log request.
 */
export interface V1MfpLogsCreateRequest {
    /**
     * Endpoint Type
     */
    endpointType: EndpointType;
    info:         V1ObjectsMfpLogInfo;
    /**
     * Response message of mfp log
     */
    responseMessage: string;
    /**
     * Spooler of Batch Object
     */
    spoolerId: string;
    /**
     * Synchronizer ID
     */
    syncId: string;
}

/**
 * Endpoint Type
 *
 * worksapceservice name
 *
 * workspaceservice name
 */
export enum EndpointType {
    Box = "box",
    Docab = "docab",
    Docard = "docard",
    Dropbox = "dropbox",
    Email = "email",
    Googledrive = "googledrive",
    Googleteamdrive = "googleteamdrive",
    Hotprofile = "hotprofile",
    Kintone = "kintone",
    Nonecloud = "nonecloud",
    Onedrive = "onedrive",
    Sharepointonline = "sharepointonline",
}

/**
 * Definition of mfp log info Object.
 */
export interface V1ObjectsMfpLogInfo {
    /**
     * MFP user name
     */
    mfpUser: string;
    /**
     * the number of pages
     */
    pages:               number;
    pdfAttributeData?:   V1ObjectsEndpointsDefaultSettingPDFAttributeData;
    printAttributeData?: V1ObjectsEndpointsDefaultSettingPrintAttributeData;
    /**
     * process
     */
    process: Process;
    /**
     * Fax reception setting
     */
    receiveAttributeData?: ReceiveAttributeData;
    scanAttributeData?:    V1ObjectsEndpointsDefaultSettingScanAttributeData;
    source:                V1ObjectsClientApp;
}

/**
 * process
 */
export enum Process {
    Print = "print",
    Receive = "receive",
    Scan = "scan",
}

/**
 * Fax reception setting
 */
export interface ReceiveAttributeData {
    /**
     * Save format
     */
    outputType: number;
    [property: string]: any;
}

/**
 * Definition of create Log Response
 */
export interface V1MfpLogsCreateResponse {
    /**
     * accepted | completed
     */
    status: V1EndpointsStatusShowResponseStatus;
}

/**
 * 複合機情報更新リクエスト
 */
export interface V1MfpPatchRequest {
    /**
     * 複合機のコメント
     */
    mfpComment?: string;
    /**
     * 複合機の設置場所
     */
    mfpLocation?: string;
    /**
     * 複合機の名前
     */
    mfpName: string;
    /**
     * 複合機の機械番号(暗号化した内容を設定)
     */
    mfpNumber: string;
}

/**
 * 複合機情報更新レスポンス
 */
export interface V1MfpPatchResponse {
    /**
     * 更新結果
     */
    status: V1EndpointsStatusShowResponseStatus;
}

/**
 * 複合機情報取得
 */
export interface V1MfpShowResponse {
    /**
     * Mfp comment
     */
    mfpComment?: string;
    /**
     * Mfp Id
     */
    mfpId: string;
    /**
     * Mfp location
     */
    mfpLocation?: string;
    /**
     * Mfp name
     */
    mfpName?: string;
    /**
     * Mfp machine number
     */
    mfpNumber: string;
    /**
     * PIN-entry can be skipped or not
     */
    omitPin?: boolean;
    /**
     * State
     */
    status: V1ObjectsWorkspacemfpStatus;
    [property: string]: any;
}

/**
 * 複合機アクティベートリクエスト
 */
export interface V1MfpUpdateRequest {
    /**
     * 複合機のコメント
     */
    mfpComment?: string;
    /**
     * 複合機の設置場所
     */
    mfpLocation?: string;
    /**
     * 複合機の名前
     */
    mfpName: string;
    /**
     * 複合機の機械番号(暗号化した内容を設定)
     */
    mfpNumber: string;
    /**
     * デバイス登録用PIN(数字6桁)
     */
    mfpPin?: string;
    /**
     * PIN省略を有効にするかどうか
     */
    omitPin?: boolean;
}

/**
 * 複合機アクティベートレスポンス
 */
export interface V1MfpUpdateResponse {
    /**
     * 更新結果
     */
    status: V1EndpointsStatusShowResponseStatus;
}

/**
 * 複合機利用可能ユーザー登録リクエスト
 */
export interface V1MfpUsersCreateRequest {
    /**
     * 現在ログイン中のユーザーのメールアドレス
     */
    email: string;
    /**
     * 複合機の機体番号を暗号化した文字列
     */
    mfpNumber: string;
    /**
     * 複合機の機種名
     */
    modelName: string;
    /**
     * 複合機のSmartSDKバージョン
     */
    smartSdkApiVer: string;
    /**
     * 複合機のベンダー名
     */
    vendorName: string;
}

/**
 * この複合機を利用可能なユーザー名の一覧を取得する。
 */
export interface V1MfpUsersIndexResponse {
    /**
     * 複合機ユーザーリスト
     */
    users: V1ObjectsUser[];
}

/**
 * 複合機ユーザー
 */
export interface V1ObjectsUser {
    /**
     * ログインユーザー名
     */
    deviceLoginUser: string;
    /**
     * SIOS COVASのアカウントメールアドレス
     */
    email: string;
    /**
     * 名前
     */
    name: string;
    /**
     * フリガナ
     */
    phoneticName: string;
}

/**
 * Mfp User Info Response
 */
export interface V1MfpUsersShowResponse {
    /**
     * Lock of mfp_user
     */
    isLock?: boolean;
    /**
     * shared PIN of the mfp list
     */
    pin?: string;
    /**
     * mfp user info
     */
    userInfo: V1ObjectsMfpUserInfo;
}

/**
 * mfp user info
 *
 * Mfp User Info Object
 */
export interface V1ObjectsMfpUserInfo {
    /**
     * name by administrator
     */
    deviceName?: string;
    /**
     * 登録されているグループ
     */
    groups?: V1ObjectsGroup[];
    /**
     * id of MFP Users
     */
    id: string;
    /**
     * Location of MFP
     */
    location: string;
    /**
     * Product name of MFP
     */
    mfpName: string;
    /**
     * Serial No of MFP
     */
    mfpNumber: string;
}

/**
 * Mfp user status response
 */
export interface V1MfpUsersStatusShowResponse {
    /**
     * status
     */
    status: FileStatusEnum;
}

/**
 * status
 *
 * お知らせステータス
 *
 * waiting | completed | failed
 *
 * 成否
 *
 * ファイルのステータス
 */
export enum FileStatusEnum {
    Completed = "completed",
    Failed = "failed",
    Waiting = "waiting",
}

/**
 * MFPユーザー要求定義の更新
 */
export interface V1MfpUsersUpdateRequest {
    /**
     * 複合機の機体番号を暗号化した文字列
     */
    mfpNumber: string;
    /**
     * PIN
     */
    pin: string;
    /**
     * options to send plain text data
     */
    skipDecryption?: boolean;
}

/**
 * アナウンスメールリクエスト
 */
export interface V1NoticeCreateRequest {
    /**
     * 送信先
     */
    destination?: V1ObjectsNoticeDestination;
    /**
     * メールの本文
     */
    mailtext?: string;
    /**
     * メールの件名
     */
    mailtitle?: string;
    /**
     * noticesのID。再送信時に必須。
     */
    noticeId?: string;
    /**
     * 送信エラー時に再送信するかどうか。
     * 再送信する場合は、前回リクエスト時に保存されたデータを使用する。
     * true: 再送信する
     * false: 再送信しない
     */
    resend?: string;
}

/**
 * 送信先
 */
export enum V1ObjectsNoticeDestination {
    Allusers = "allusers",
    Resend = "resend",
    System = "system",
    Workspaces = "workspaces",
}

/**
 * アナウンスメールレスポンス
 */
export interface V1NoticeCreateResponse {
    /**
     * 送信結果
     */
    success: boolean;
}

/**
 * アナウンスメール一覧のレスポンス
 */
export interface V1NoticeIndexResponse {
    /**
     * アナウンスメール一覧
     */
    notices: V1ObjectsNoticeInfo[];
    /**
     * ページネーション
     */
    pagination?: V1ObjectsPagination;
}

/**
 * お知らせ情報オブジェクト
 */
export interface V1ObjectsNoticeInfo {
    /**
     * 完了数
     */
    complete?: number;
    /**
     * 作成日時
     */
    createdAt?: string;
    /**
     * エラー数
     */
    error?: number;
    /**
     * お知らせID
     */
    id?: string;
    /**
     * お知らせステータス
     */
    status?: FileStatusEnum;
    /**
     * お知らせ内容
     */
    text?: string;
    /**
     * お知らせタイトル
     */
    title?: string;
    /**
     * 全体数
     */
    totalCount?: number;
    /**
     * 更新日時
     */
    updatedAt?: string;
}

/**
 * ワークスペースユーザーの一括登録リクエストのワークスペースユーザー情報
 */
export interface V1ObjectsCollectiveInvite {
    /**
     * 連絡先Eメールアドレス
     */
    contactEmail: string;
    /**
     * 複合機ユーザー名
     */
    deviceLoginUser: string;
    /**
     * メールアドレス
     */
    email: string;
    /**
     * グループ
     */
    group?: string[];
    /**
     * 言語
     */
    language: Language;
    /**
     * メンバー登録時に同時に登録する複合機番号
     */
    mfpNumber: string[];
    /**
     * 名前
     */
    name: string;
    /**
     * ログインパスワード
     */
    password: string;
    /**
     * フリガナ
     */
    phoneticName: string;
    /**
     * 複合機PIN
     */
    pin: string;
    /**
     * 権限
     */
    role: string;
}

/**
 * Definition of create request and create response workspace mfps collective.
 */
export interface V1ObjectsCollectiveMfp {
    /**
     * 登録する利用可能メンバー
     */
    email: string[];
    /**
     * 登録するグループ名リスト
     */
    groups: string[];
    /**
     * 複合機名
     */
    mfpName: string;
    /**
     * 登録する機械番号
     */
    mfpNumber: string;
    /**
     * PIN認証の省略フラグ
     */
    omitPin: boolean;
}

/**
 * Collective invite response status
 */
export enum V1ObjectsCollectiveStatus {
    Error = "error",
    Ok = "ok",
    Timeout = "timeout",
}

/**
 * デフォルトグループの一覧
 */
export enum V1ObjectsDefaultGroup {
    Everyone = "Everyone",
}

/**
 * Endpoint default setting object
 */
export interface V1ObjectsEndpointExtension {
    /**
     * deleteFlag
     */
    deleteFlag: boolean;
    /**
     * endpoint extension type
     */
    extensionType: ExtensionType;
    /**
     * extension id
     */
    id?: string;
    /**
     * flag stop extension when error
     */
    isStopWhenError: boolean;
    /**
     * extension name
     */
    name: string;
    /**
     * workspace extension id
     */
    workspaceExtensionId: string;
}

/**
 * endpoint extension type
 */
export enum ExtensionType {
    NjkOcr = "njk-ocr",
    Print = "print",
    Scan = "scan",
}

/**
 * index entry data
 */
export interface V1ObjectsEntryIndex {
    /**
     * 解約日
     */
    cancelDate?: string;
    /**
     * 申し込み区分
     */
    category: string;
    /**
     * お客様企業名
     */
    clientCompany: string;
    /**
     * お客様部署名
     */
    clientDepartment: string;
    /**
     * 担当者-名
     */
    clientFirstName: string;
    /**
     * 担当者-姓
     */
    clientLastName: string;
    /**
     * メールアドレス
     */
    clientMail: string;
    /**
     * 電話番号
     */
    clientPhone: string;
    /**
     * 申し込みクラウド
     */
    cloud: V1ObjectsEntryCloud;
    /**
     * 申し込み日
     */
    createdAt?: string;
    /**
     * 削除日
     */
    deleteDate?: string;
    /**
     * 申し込み番号
     */
    entryNumber: string;
    /**
     * 申し込み状況
     */
    entryState?: V1ObjectsEntryState;
    /**
     * id of entry model
     */
    id: string;
    /**
     * 大塚商会担当エンジニア-名
     */
    otsEngineerFirstName: string;
    /**
     * 大塚商会担当エンジニア-姓
     */
    otsEngineerLastName: string;
    /**
     * 大塚商会担当エンジニアメールアドレス
     */
    otsEngineerMail: string;
    /**
     * 大塚商会担当営業部署名
     */
    otsSalesDepartment: string;
    /**
     * 大塚商会担当営業-名
     */
    otsSalesFirstName: string;
    /**
     * 大塚商会担当営業-姓
     */
    otsSalesLastName: string;
    /**
     * 大塚商会担当営業メールアドレス
     */
    otsSalesMail: string;
    /**
     * 大塚商会担当営業電話番号
     */
    otsSalesPhone: string;
    /**
     * 申し込み製品
     */
    products: V1ObjectsEntryProducts;
    /**
     * ステータスがcreate以降なら必要
     */
    workspace?: V1ObjectsWorkspace;
}

/**
 * ステータスがcreate以降なら必要
 *
 * Definition of Workspace.
 *
 * ワークスペース
 *
 * workspace
 */
export interface V1ObjectsWorkspace {
    /**
     * active or not
     */
    active?: boolean;
    /**
     * billing plan of workspace
     */
    billingPlan?: string;
    /**
     * brand of workspace
     */
    brand?: string;
    /**
     * 申込画面の表示許可
     */
    canViewEntryList?: boolean;
    /**
     * child workspace Ids
     */
    childWorkspaces?: string[];
    /**
     * contact email
     */
    contactCCEmail?: string[];
    /**
     * contact email
     */
    contactEmail?: string;
    /**
     * delete or not
     */
    deleteFlag?: boolean;
    /**
     * code name of workspace
     */
    displayId: string;
    /**
     * name of workspace
     */
    displayName: string;
    /**
     * permit create child workspace
     */
    enableSubWorkspace?: boolean;
    /**
     * id of workspace
     */
    id?: string;
    /**
     * invitation workspace user
     */
    invitationUser?: V1ObjectsWorkspaceuserLarge;
    /**
     * workspace language
     */
    language?: Language;
    /**
     * logo url of workspace
     */
    logoUrl?: string;
    /**
     * not verify user count
     */
    notVerifyUsercount?: number;
    /**
     * organization detail info
     */
    organizationDetail?: V1ObjectsWorkspacesOrganizationDetail;
    /**
     * parent workspace Id
     */
    parentWorkspace?: string;
    /**
     * invite verified flag
     */
    verified?: boolean;
    /**
     * invitation verify token
     */
    verifyToken?: string;
    /**
     * verify user count
     */
    verifyUsercount?: number;
}

/**
 * invitation workspace user
 *
 * Definition of Workspace user.
 *
 * 依頼人
 *
 * 登録先を選択したときのワークスペースユーザー
 *
 * ユーザー
 *
 * 登録先を選択したときのワークスペースユーザー。
 *
 * 送信先ワークスペースユーザー。
 *
 * ワークスペースユーザー
 *
 * workspace user
 */
export interface V1ObjectsWorkspaceuserLarge {
    /**
     * active user flag
     */
    active: boolean;
    /**
     * avatar icon url
     */
    avatarUrl: string;
    /**
     * 連絡先Eメールアドレス
     */
    contactEmail:    string;
    deviceLoginUser: string;
    /**
     * email notification status
     */
    emailNotification?: V1ObjectsWorkspaceusersEmailNotification;
    /**
     * id of worksapceuser
     */
    id: string;
    /**
     * invitation date
     */
    invitationDate: string;
    /**
     * invitation email address
     */
    invitationEmail: string;
    /**
     * possible date for sign-up
     */
    invitationEndDate: string;
    /**
     * invitation certified flag
     */
    invitationVerified: boolean;
    /**
     * Email sent or not.
     */
    invited?: boolean;
    /**
     * invited user id
     */
    invitedUser: string;
    /**
     * workspace user language
     */
    language: Language;
    /**
     * workspace user name
     */
    name: string;
    /**
     * workspace user phonetic name
     */
    phoneticName: string;
    /**
     * mfp auth pin
     */
    pin: string;
    /**
     * role in the workspace
     */
    role: number;
    /**
     * authentication user id
     */
    user: string;
    /**
     * invitation verify token
     */
    verifyToken?: string;
    /**
     * id of the belonging workspace
     */
    workspace: string;
}

/**
 * email notification status
 *
 * メール通知設定
 */
export interface V1ObjectsWorkspaceusersEmailNotification {
    /**
     * notification when service error
     */
    serviceError?: boolean;
    /**
     * notification when having a document sharing event
     */
    sharingEvent?: boolean;
    /**
     * notification when having a message from support
     */
    supportMessage?: boolean;
}

/**
 * organization detail info
 *
 * organization detail
 *
 * 組織情報
 */
export interface V1ObjectsWorkspacesOrganizationDetail {
    /**
     * organization address1
     */
    address1?: string;
    /**
     * organization address2
     */
    address2?: string;
    /**
     * country
     */
    country?: string;
    /**
     * customer id
     */
    customerId?: string;
    /**
     * organization name
     */
    name?: string;
    /**
     * organization phone number
     */
    phoneNumber?: string;
    /**
     * organization zip code
     */
    zipCode?: string;
}

/**
 * Event log aggregate results object
 */
export interface V1ObjectsEventlogAggregateResults {
    /**
     * action
     */
    action: string;
    /**
     * aggregate type
     */
    aggregateType: string;
    /**
     * count
     */
    count: number;
    /**
     * endpoint type
     */
    endpointType: string;
    /**
     * output type
     */
    outputType: number;
    /**
     * service
     */
    service: string;
    /**
     * timestamp
     */
    timestamp: string;
    /**
     * workspaceId
     */
    workspaceId: string;
}

/**
 * workspace extension info object
 */
export interface V1ObjectsExtension {
    /**
     * company name
     */
    companyName: string;
    /**
     * description of extension
     */
    description: string;
    /**
     * workspace extension id
     */
    id: string;
    /**
     * active flag
     */
    isActive: boolean;
    /**
     * extension name
     */
    name: string;
    /**
     * extension version
     */
    version: string;
}

/**
 * JSON file data
 */
export interface V1ObjectsJsonfile {
    /**
     * base64 string contains json data from fe
     */
    content: string;
    /**
     * key to authen to cloud service
     */
    name: string;
}

/**
 * どのメールの送信状況を取得するかを指定する時に使用する
 */
export enum V1ObjectsMailResourceType {
    Announce = "announce",
    WorkspaceInvite = "workspaceInvite",
    XStoragesNoWsUser = "XStoragesNoWsUser",
    XStoragesOrder = "xStoragesOrder",
}

/**
 * ユーザーエクスポート時のパスワード
 */
export enum V1ObjectsPassExportConst {
    暗号化 = "暗号化[***]",
}

/**
 * 認可依頼作成の際に使用する登録先ユーザーの種別
 *
 * 依頼タイプ
 * eachUser: 選択したユーザー、グループのクラウドストレージに登録する
 * oneUser: 1ユーザーのクラウドストレージに全て登録する、共有クラウドストレージ
 */
export enum V1ObjectsRegisteredUserEnum {
    EachUser = "eachUser",
    OneUser = "oneUser",
}

/**
 * Definition of spooler batch task object.
 */
export interface V1ObjectsSpoolerBatchTask {
    /**
     * 作成日
     */
    createdAt: string;
    /**
     * SDCのAzure Blob Storageオブジェクト(SAS付き)
     */
    destination: string;
    /**
     * UUID
     */
    id: string;
    /**
     * ファイル名
     */
    name: string;
}

/**
 * Definition of spooler object.
 */
export interface V1ObjectsSpoolersIndex {
    /**
     * SyncObject created time
     */
    createdAt: number;
    /**
     * Uploaded device's name
     */
    deviceName: string;
    /**
     * SyncObject owner's email
     */
    email: string;
    /**
     * Endpoint's button color
     */
    endpointBtnColor: V1ObjectsEndpointsButtonColor;
    /**
     * Endpoint's icon
     */
    endpointIcon: string;
    /**
     * Endpoint's name
     */
    endpointName: string;
    /**
     * Expiry date
     */
    expiryDate: number;
    /**
     * List of file's name
     */
    files: string[];
    /**
     * Path to uploaded folder (from Endpoint as root)
     */
    folder: string;
    /**
     * SyncObjectID
     */
    id: string;
    /**
     * Endpoint owner's name
     */
    owner: string;
    /**
     * Process's history
     */
    processHistory?: V1ObjectsSpoolersProcessHistory[];
    /**
     * SyncObject's status  - started >> running | failed >> stopped | completed >> completed
     */
    processStatus: ProcessStatus;
    /**
     * SyncObject owner's name
     */
    userName: string;
}

/**
 * Definition of spooler's process history.
 */
export interface V1ObjectsSpoolersProcessHistory {
    /**
     * Process's name
     */
    name?: string;
    /**
     * Error summary
     */
    note?: string;
    /**
     * Process result
     */
    result: Result;
    /**
     * Process history type
     */
    type: V1ObjectsSpoolersProcessTypeEnum;
    /**
     * Updated time
     */
    updatedAt: number;
}

/**
 * Process result
 */
export enum Result {
    Error = "error",
    Success = "success",
}

/**
 * Process history type
 *
 * Enum of process type
 */
export enum V1ObjectsSpoolersProcessTypeEnum {
    Download = "download",
    Extension = "extension",
    Upload = "upload",
}

/**
 * SyncObject's status  - started >> running | failed >> stopped | completed >> completed
 */
export enum ProcessStatus {
    Completed = "completed",
    Running = "running",
    Stopped = "stopped",
}

/**
 * Enum of cloud-services
 */
export enum V1ObjectsSpoolersSourceEnum {
    Browser = "browser",
    Mfp = "mfp",
}

/**
 * Enum of sync_objects.status
 */
export enum V1ObjectsSyncObejctsStatus {
    Completed = "completed",
    Failed = "failed",
    Started = "started",
}

/**
 * 同期ステータスの列挙型。
 * waiting: 処理待機中
 * started: 処理開始済み
 * completed: 完了
 * failed: 失敗
 */
export enum V1ObjectsSyncStatusEnum {
    Completed = "completed",
    Failed = "failed",
    Started = "started",
    Waiting = "waiting",
}

/**
 * 転送元情報.転送先情報.
 */
export interface V1ObjectsSyncTarget {
    /**
     * List of email to be sent to
     */
    addressTo?: string[];
    /**
     * x-doc-card-dpi for business card
     */
    dpi?: number;
    /**
     * エンドポイントのIDを設定
     */
    id?: string;
    /**
     * x-doc-card-lang for business card
     */
    lang?: string;
    /**
     * x-doc-local-authorized-id for business card
     */
    mfpUserId?: string;
    /**
     * x-doc-local-authorized-name for business card
     */
    mfpUserName?: string;
    /**
     * パス
     */
    path?: string;
    /**
     * パスID
     */
    pathId?: string;
    /**
     * x-doc-card-sharing for business card
     */
    sharing?: string;
    /**
     * Subject to be inclueded in email
     */
    subject?: string;
    /**
     * エンドポイントのIDを設定
     */
    targetType: TargetType;
    /**
     * UserID to register business card
     */
    userId?: string;
    /**
     * Flag check write permission
     */
    writeable?: boolean;
}

/**
 * エンドポイントのIDを設定
 */
export enum TargetType {
    Endpoint = "endpoint",
    Spooler = "spooler",
}

/**
 * wordbook info object
 *
 * wordbook
 */
export interface V1ObjectsWordbook {
    /**
     * industryType wordbooks
     */
    name: string;
    /**
     * list template wordbooks
     */
    wordList: string[];
}

/**
 * V1ObjectsWorkspacemfpuser
 */
export interface V1ObjectsWorkspacemfpuser {
    /**
     * user avatar url
     */
    avatarUrl: string;
    /**
     * user email
     */
    email: string;
    /**
     * id of new workspacemfp user
     */
    id: string;
    /**
     * active flag
     */
    isActive: boolean;
    /**
     * name of workspace user
     */
    name: string;
    /**
     * user phonetic name
     */
    phoneticName: string;
    /**
     * user role
     */
    role: number;
}

/**
 * workspace service setting status
 */
export interface V1ObjectsWorkspacesCloudSetting {
    /**
     * key to authen to cloud service
     */
    apiKey?: string;
    /**
     * allow 2L-OAuth method
     */
    is2L: boolean;
    /**
     * allow 3L-OAuth method
     */
    is3L: boolean;
    /**
     * allow download from cloud storage
     */
    isDownloadable: boolean;
    /**
     * allow upload to cloud storage
     */
    isUploadable: boolean;
    /**
     * key to authen to cloud service
     */
    jsonFileNames?: string[];
    /**
     * worksapceservice id
     */
    serviceId: string;
    /**
     * worksapceservice name
     */
    serviceName: EndpointType;
}

/**
 * workspace and MFP combination object
 */
export interface V1ObjectsWorkspacesMfpCombination {
    /**
     * MFP device name
     */
    mfpDeviceName: string;
    /**
     * MFP id
     */
    mfpId: string;
    /**
     * MFP location
     */
    mfpLocation: string;
    /**
     * MFP number
     */
    mfpName: string;
    /**
     * MFP number
     */
    mfpNumber: string;
    /**
     * ワークスペース
     */
    workspace: V1ObjectsWorkspace;
}

/**
 * workspace mfp info object
 */
export interface V1ObjectsWorkspacesMfpInfo {
    /**
     * existSetting flag
     */
    existSetting: boolean;
    /**
     * worksapce-mfp id
     */
    id: string;
    /**
     * mfp number
     */
    mfpNumber: string;
    /**
     * registration date
     */
    registrationDate: string;
    /**
     * registration end date
     */
    registrationEndDate: string;
    /**
     * registration pin
     */
    registrationPin: string;
    /**
     * registration user name
     */
    registrationUser: string;
}

/**
 * 分析レポートの送信対象ユーザー
 */
export interface V1ObjectsWorkspacesNotificationTargetUser {
    /**
     * 送信対象かどうかのフラグ
     */
    analyticsReport: boolean;
    /**
     * ワークスペースユーザー
     */
    user: User;
}

/**
 * ワークスペースユーザー
 */
export interface User {
    /**
     * アカウント停止フラグ
     */
    active: boolean;
    /**
     * アバター画像URL
     */
    avatarUrl: string;
    /**
     * 連絡先メールアドレス
     */
    contactEmail: string;
    /**
     * workspace_users._id
     */
    id: string;
    /**
     * 招待メールアドレス
     */
    invitationEmail: string;
    /**
     * 言語
     */
    language: Language;
    /**
     * ユーザー名
     */
    name: string;
    /**
     * ユーザー名（フリガナ）
     */
    phoneticName: string;
    /**
     * ロール
     */
    role: number;
}

/**
 * user's xsotrage object
 */
export interface V1ObjectsXStorage {
    /**
     * Authorization exprie date
     */
    authorizationDeadline: string;
    /**
     * Authorization id (email)
     */
    authorizationId?: string;
    /**
     * link to grand access page
     */
    authorizationLink: string;
    /**
     * 最後に認可発行したアプリの記録.Googleドライブアプリの統合に使用。
     */
    clientType?: V1ObjectsServiceEnum;
    /**
     * Xstorage background image
     */
    imageLink?: string;
    /**
     * x-storage method to conect to service server
     */
    is2L?: boolean;
    /**
     * admin setting for download
     */
    isDownloadable?: boolean;
    /**
     * admin setting for upload
     */
    isUploadable?: boolean;
    service:       V1ObjectsServiceEnum;
    /**
     * share type
     */
    share?: number;
    /**
     * status notAuthored if no error
     */
    status: V1ObjectsXStorageStatus;
    /**
     * xstorages id
     */
    xstorageId: string;
}

/**
 * status notAuthored if no error
 *
 * status that should be returned
 */
export enum V1ObjectsXStorageStatus {
    Expiried = "expiried",
    NotAuthored = "notAuthored",
    Success = "success",
}

/**
 * クラウド認可作成依頼詳細一覧取得
 */
export interface V1ObjectsXStoragesOrderHistory {
    /**
     * 作成日
     */
    createdAt: string;
    /**
     * ワークスペースユーザー以外のメールアドレス
     */
    externalEmail?: string;
    /**
     * 認可依頼履歴のID
     */
    id: string;
    /**
     * 期限日
     */
    limit: string;
    /**
     * 依頼のマスターデータ
     */
    master?: V1ObjectsXStoragesOrderMaster;
    /**
     * 認可状況
     */
    status: boolean;
    /**
     * ユーザー
     */
    user?: V1ObjectsWorkspaceuserLarge;
}

/**
 * 依頼のマスターデータ
 *
 * クラウド認可作成依頼
 */
export interface V1ObjectsXStoragesOrderMaster {
    /**
     * 許可ドメイン
     */
    allowedDomain: string;
    /**
     * 依頼人
     */
    client?: V1ObjectsWorkspaceuserLarge;
    /**
     * 認可依頼日
     */
    createdAt: string;
    /**
     * 完了の人数
     */
    done: number;
    /**
     * 認可依頼マスターデータのID
     */
    id: string;
    /**
     * 依頼した総人数
     */
    max: number;
    /**
     * 登録先を選択したときのワークスペースユーザー
     */
    registeredUser?: V1ObjectsWorkspaceuserLarge;
    /**
     * クラウド種別
     */
    service: V1ObjectsServiceEnum;
    /**
     * クラウドストレージ(0) 共有クラウドストレージ(1)
     */
    share?: number;
    /**
     * 更新日
     */
    updatedAt: string;
}

/**
 * 新規クラウド認可作成依頼リクエストの詳細
 */
export interface V1ObjectsXStoragesOrderInfo {
    /**
     * ワークスペースユーザー以外のメールアドレス
     */
    externalEmail?: string;
    /**
     * 登録先を選択したときのワークスペースユーザー。
     */
    registeredUser?: V1ObjectsWorkspaceuserLarge;
    /**
     * 送信先ワークスペースユーザー。
     */
    toUser?: V1ObjectsWorkspaceuserLarge;
}

/**
 * ワークスペースユーザーの認可ステータス
 */
export interface V1ObjectsXStoragesStatus {
    /**
     * 認可済みの場合、アカウントのメールアドレスを入れる
     */
    account: string[];
    /**
     * 認可状況。複数の認可がある場合は、1個でも有効ならtrue。
     * アカウントのドメインが一致していない場合は未認可扱い。
     */
    serviceStatus: boolean;
    /**
     * ワークスペースユーザー
     */
    user: V1ObjectsWorkspaceuserLarge;
}

/**
 * Definition of create Spooler Batch request.
 */
export interface V1SpoolersBatchCreateRequest {
    /**
     * アップロードファイルリスト
     */
    files: V1SpoolersBatchCreateRequestFile[];
    /**
     * ない場合は、新規に採番して、batchObjectを作成、ある場合は、その情報を元にbatchObjectを作成
     */
    id?: string;
}

export interface V1SpoolersBatchCreateRequestFile {
    /**
     * ファイル名
     */
    name: string;
    [property: string]: any;
}

/**
 * Spooler Batch Response
 */
export interface V1SpoolersBatchShowResponse {
    /**
     * 作成日
     */
    createdAt: string;
    /**
     * バッチID
     */
    id: string;
    /**
     * waiting | completed | failed
     */
    status: FileStatusEnum;
    /**
     * タスク配列
     */
    tasks: V1ObjectsSpoolerBatchTask[];
    /**
     * 更新日
     */
    updatedAt: string;
}

/**
 * Definition of update Spooler Batch Status request.
 */
export interface V1SpoolersBatchStatusUpdateRequest {
    /**
     * completed | failed
     */
    status: V1SpoolersBatchStatusUpdateRequestStatus;
}

/**
 * completed | failed
 */
export enum V1SpoolersBatchStatusUpdateRequestStatus {
    Completed = "completed",
    Failed = "failed",
}

/**
 * Definition of v1-spoolers-destroy-response.
 */
export interface V1SpoolersDestroyResponse {
    status: V1ObjectsModifyResponseStatusEnum;
}

/**
 * Definition of v1-spoolers-download-show-response.
 */
export interface V1SpoolersDownloadShowResponse {
    recoverLink: string;
}

/**
 * Definition of v1-spoolers-index-response.
 */
export interface V1SpoolersIndexRequest {
    limit?:     string;
    offset?:    string;
    queryType?: QueryType;
    source?:    V1ObjectsSpoolersSourceEnum;
}

export enum QueryType {
    Completed = "completed",
    Deleted = "deleted",
    Error = "error",
    Processing = "processing",
}

/**
 * Definition of v1-spoolers-index-response.
 */
export interface V1SpoolersIndexResponse {
    /**
     * Array of Spooler info
     */
    data:    V1ObjectsSpoolersIndex[];
    limit?:  number;
    offset?: number;
}

/**
 * 完了通知
 */
export interface V1SpoolersPatchRequest {
    /**
     * 成否
     */
    status?: FileStatusEnum;
}

/**
 * Definition of create File Sync request.
 */
export interface V1SyncCreateRequest {
    from: V1ObjectsSyncTarget;
    /**
     * add (子番保存), overwrite (上書き保存) ※省略時は add
     */
    mode?:  Mode;
    source: V1ObjectsClientApp;
    to:     V1ObjectsSyncTarget;
}

/**
 * add (子番保存), overwrite (上書き保存) ※省略時は add
 *
 * Mode for upload when cloud storage already have file with same name
 */
export enum Mode {
    Add = "add",
    Overwrite = "overwrite",
}

/**
 * File Sync Response
 */
export interface V1SyncCreateResponse {
    /**
     * 作成日
     */
    createdAt: string;
    from:      V1ObjectsSyncTarget;
    /**
     * Synchronizer ID
     */
    id:     string;
    source: V1ObjectsClientApp;
    /**
     * started | completed | failed | deleted
     */
    status: V1SyncCreateResponseStatus;
    to:     V1ObjectsSyncTarget;
    /**
     * 更新日
     */
    updatedAt: string;
}

/**
 * started | completed | failed | deleted
 */
export enum V1SyncCreateResponseStatus {
    Completed = "completed",
    Deleted = "deleted",
    Failed = "failed",
    Started = "started",
}

/**
 * Definition of create Upload-sync request.
 */
export interface V1UploadsyncCreateRequest {
    /**
     * アップロードファイルリスト
     */
    files: V1UploadsyncCreateRequestFile[];
    /**
     * Mode for upload when cloud storage already have file with same name
     */
    mode?:  Mode;
    source: V1ObjectsClientApp;
    to:     V1ObjectsSyncTarget;
}

export interface V1UploadsyncCreateRequestFile {
    /**
     * ファイル名
     */
    name: string;
    [property: string]: any;
}

/**
 * Definition of create Upload-sync response.
 */
export interface V1UploadsyncCreateResponse {
    /**
     * 作成日
     */
    createdAt: string;
    /**
     * スプールID。batch_objects.spooler
     */
    id:     string;
    source: V1ObjectsClientApp;
    /**
     * waiting | completed | failed
     */
    status: FileStatusEnum;
    /**
     * タスク配列
     */
    tasks: V1ObjectsSpoolerBatchTask[];
    /**
     * 更新日
     */
    updatedAt: string;
}

/**
 * Definition of update Upload-sync Status request.
 */
export interface V1UploadsyncPatchRequest {
    /**
     * completed | failed
     */
    status: V1SpoolersBatchStatusUpdateRequestStatus;
}

/**
 * Definition of update Upload-sync Status response.
 */
export interface V1UploadsyncPatchResponse {
    /**
     * 作成日
     */
    createdAt: string;
    from:      V1ObjectsSyncTarget;
    /**
     * Synchronizer ID
     */
    id:     string;
    source: V1ObjectsClientApp;
    /**
     * started | completed | failed | deleted
     */
    status: V1SyncCreateResponseStatus;
    to:     V1ObjectsSyncTarget;
    /**
     * 更新日
     */
    updatedAt: string;
}

/**
 * Update Upload-Sync Status Response
 */
export interface V1UploadsyncStatusPatchResponse {
    status: V1UploadsyncStatusPatchResponseStatus;
}

export enum V1UploadsyncStatusPatchResponseStatus {
    Accepted = "accepted",
}

/**
 * Definition of create user (b2c) request.
 */
export interface V1UserCreateRequest {
    /**
     * ログイン用のEメールアドレス
     */
    email: string;
    /**
     * パスワード
     */
    password: string;
    /**
     * workspaces.displyId
     */
    workspace: string;
}

/**
 * Definition of user (b2c) response.
 */
export interface V1UserCreateResponse {
    /**
     * user object id
     */
    id: string;
    /**
     * workspace user
     */
    user: V1ObjectsWorkspaceuserLarge;
    /**
     * workspace
     */
    workspace: V1ObjectsWorkspace;
}

/**
 * User Mfp Info Response
 */
export interface V1UserMfpsShowResponse {
    /**
     * Lock of mfp_user
     */
    isLock?: boolean;
    /**
     * mfp user list data
     */
    mfpUsers: V1ObjectsMfpUserInfo[];
    /**
     * pagination
     */
    pagination?: V1ObjectsPagination;
    /**
     * shared PIN of the mfp list
     */
    pin?: string;
}

/**
 * Definition of update user password request.
 */
export interface V1UserUpdateRequest {
    /**
     * ログイン用のEメールアドレス
     */
    email: string;
    /**
     * パスワード
     */
    password: string;
    /**
     * workspaces.displyId
     */
    workspace: string;
}

/**
 * Definition of update user password response.
 */
export interface V1UserUpdateResponse {
    /**
     * user object id
     */
    id: string;
}

/**
 * Definition of v1-wordbooks-index-request.
 */
export interface V1WordbooksIndexRequest {
    /**
     * brand for workspaces
     */
    brand?: Brand;
}

/**
 * brand for workspaces
 *
 * brand
 */
export enum Brand {
    Otsuka = "otsuka",
    Ricoh = "ricoh",
    System = "system",
}

/**
 * List wordbook under master management
 */
export interface V1WordbooksIndexResponse {
    /**
     * wordbook list
     */
    templates: V1ObjectsWordbook[];
}

/**
 * Wordbook under master management
 */
export interface V1WordbooksShowResponse {
    /**
     * wordbook
     */
    templates: V1ObjectsWordbook;
}

/**
 * 分析レポートのダウンロード開始リクエスト
 *
 * ダウンロード開始リクエスト時のリクエスト内容のJSON
 */
export interface V1WorkspaceAnalyticsDownloadCreateRequest {
    /**
     * 分析レポートの種類(将来的に増える予定)
     */
    analyticsType: AnalyticsType;
    /**
     * ダウンロード期間終了日時(UTC)
     */
    dataEnd: string;
    /**
     * ダウンロード期間開始日時(UTC)
     */
    dataStart: string;
}

/**
 * 分析レポートの種類(将来的に増える予定)
 */
export enum AnalyticsType {
    Transfer = "transfer",
}

/**
 * 分析レポートのダウンロード開始レスポンス
 */
export interface V1WorkspaceAnalyticsDownloadCreateResponse {
    /**
     * ダウンロード開始リクエスト時のリクエスト内容のJSON
     */
    analyticsOption: V1WorkspaceAnalyticsDownloadCreateRequest;
    /**
     * リクエスト日時
     */
    createdAt?: string;
    /**
     * ファイルのダウンロード完了日時
     */
    downloadedAt?: string;
    /**
     * ファイル名
     */
    fileName: string;
    /**
     * ファイルのステータス
     */
    fileStatus: FileStatusEnum;
    /**
     * ダウンロード開始リクエストのID
     */
    id: string;
    /**
     * 更新日時
     */
    updatedAt?: string;
}

/**
 * 分析レポートの送信設定レスポンス
 */
export interface V1WorkspaceAnalyticsNotificationConfigShowResponse {
    /**
     * ワークスペースの利用状況レポートの有効・無効
     */
    analyticsReport: boolean;
    /**
     * レポートの送信対象ユーザー
     */
    targetUsers: V1ObjectsWorkspacesNotificationTargetUser[];
}

/**
 * 分析レポートの送信設定レスポンス
 */
export interface V1WorkspaceAnalyticsNotificationConfigUpdateRequest {
    /**
     * ワークスペースの利用状況レポートの有効・無効
     */
    analyticsReport: boolean;
    /**
     * レポートの送信対象ユーザー
     */
    targetUsers: V1ObjectsWorkspacesNotificationTargetUser[];
}

/**
 * index workspace's services response
 */
export interface V1WorkspaceCloudSettingIndexResponse {
    /**
     * workspace storages-setting list
     */
    settings: V1ObjectsWorkspacesCloudSetting[];
}

/**
 * workspace service details
 */
export interface V1WorkspaceCloudSettingShowResponse {
    /**
     * key to authen to cloud service
     */
    apiKey?: string;
    /**
     * allow 2L-OAuth method
     */
    is2L: boolean;
    /**
     * allow 3L-OAuth method
     */
    is3L: boolean;
    /**
     * allow download from cloud storage
     */
    isDownloadable: boolean;
    /**
     * allow upload to cloud storage
     */
    isUploadable: boolean;
    /**
     * key to authen to cloud service
     */
    jsonFileNames?: string[];
    /**
     * worksapceservice id
     */
    serviceId: string;
    /**
     * worksapceservice name
     */
    serviceName: EndpointType;
}

/**
 * workspace service details
 */
export interface V1WorkspaceCloudSettingUpdateRequest {
    /**
     * key to authen to cloud service
     */
    apiKey?: string;
    /**
     * allow 2L-OAuth method
     */
    is2L: boolean;
    /**
     * allow 3L-OAuth method
     */
    is3L: boolean;
    /**
     * allow download from cloud storage
     */
    isDownloadable: boolean;
    /**
     * allow upload to cloud storage
     */
    isUploadable: boolean;
    /**
     * true if current JSON deleted (remove when have many JSON files)
     */
    jsonFileDeleted?: boolean;
    /**
     * array contains new json File
     */
    jsonFiles?: V1ObjectsJsonfile[];
    /**
     * worksapceservice id
     */
    serviceId: string;
    /**
     * worksapceservice name
     */
    serviceName: EndpointType;
}

/**
 * workspace service update response
 */
export interface V1WorkspaceCloudSettingUpdateResponse {
    /**
     * key to authen to cloud service
     */
    apiKey?: string;
    /**
     * allow 2L-OAuth method
     */
    is2L: boolean;
    /**
     * allow 3L-OAuth method
     */
    is3L: boolean;
    /**
     * allow download from cloud storage
     */
    isDownloadable: boolean;
    /**
     * allow upload to cloud storage
     */
    isUploadable: boolean;
    /**
     * key to authen to cloud service
     */
    jsonFileNames?: string[];
    /**
     * workspaceservice id
     */
    serviceId: string;
    /**
     * workspaceservice name
     */
    serviceName: EndpointType;
}

/**
 * Definition of create workspace request.
 */
export interface V1WorkspaceCreateRequest {
    /**
     * otsuka,ricoh,system
     */
    brand?: string;
    /**
     * contact email
     */
    contactEmail?: string;
    /**
     * code name of workspace
     */
    displayId: string;
    /**
     * name of workspace
     */
    displayName: string;
    /**
     * permit create child workspace
     */
    enableSubWorkspace: boolean;
    /**
     * 申込に紐づくid
     */
    entry?: string;
    /**
     * language used in workspace
     */
    language: string;
    /**
     * logo url of workspace
     */
    logoUrl?: string;
    /**
     * 組織情報
     */
    organizationDetail?: V1ObjectsWorkspacesOrganizationDetail;
    /**
     * parent workspace Id
     */
    parentWorkspace: string;
    /**
     * invite verified flag
     */
    verified?: boolean;
    /**
     * invitation verify token
     */
    verifyToken?: string;
}

/**
 * Definition of create workspace response.
 */
export interface V1WorkspaceCreateResponse {
    /**
     * contact email
     */
    contactEmail?: string;
    /**
     * code name of workspace
     */
    displayId: string;
    /**
     * name of workspace
     */
    displayName: string;
    /**
     * permit create child workspace
     */
    enableSubWorkspace: boolean;
    /**
     * id of worksapceuser
     */
    id: string;
    /**
     * language used in workspace
     */
    language: string;
    /**
     * logo url of workspace
     */
    logoUrl: string;
    /**
     * parent workspace Id
     */
    parentWorkspace: string;
}

/**
 * Definition of delete workspace response.
 */
export interface V1WorkspaceDestroyResponse {
    /**
     * id of workspace
     */
    id: string;
}

/**
 * Definition of eventlog aggregate results .request
 */
export interface V1WorkspaceEventlogAggregateResultsIndexRequest {
    /**
     * action of eventlog
     */
    action?: string;
    /**
     * aggregate type of eventlog
     */
    aggregateType?: string;
    /**
     * end date of eventlog
     */
    endDate?: string;
    /**
     * endpoint type of eventlog
     */
    endpointType?: string;
    /**
     * output type of eventlog
     */
    outputType?: number;
    /**
     * service of eventlog
     */
    service?: string;
    /**
     * start date of eventlog
     */
    startDate?: string;
    /**
     * workspaceId of eventlog
     */
    workspaceId?: string;
}

/**
 * Definition of eventlog aggregate results response.
 */
export interface V1WorkspaceEventlogAggregateResultsIndexResponse {
    /**
     * eventlogs which satisfies the condition
     */
    results: V1ObjectsEventlogAggregateResults[];
}

/**
 * グループ作成情報
 */
export interface V1WorkspaceGroupsCreateRequest {
    /**
     * グループアイコンURL
     */
    avatarUrl: string;
    /**
     * グループ名
     */
    groupName: string;
    /**
     * グループに所属するユーザーのIDリスト(workspace_users._id)
     */
    members?: string[];
}

/**
 * グループ一覧取得レスポンス
 */
export interface V1WorkspaceGroupsIndexResponse {
    /**
     * グループ総数
     */
    count: number;
    /**
     * グループ一覧
     */
    groups: V1WorkspaceGroupsShowResponse[];
}

/**
 * グループ情報
 */
export interface V1WorkspaceGroupsShowResponse {
    /**
     * グループアイコンURL
     */
    avatarUrl?: string;
    /**
     * グループ名
     */
    groupName: string;
    /**
     * グループID
     */
    id: string;
    /**
     * グループに所属するユーザーのIDリスト
     */
    members?: V1ObjectsWorkspaceuserMedium[];
}

/**
 * グループ更新情報
 */
export interface V1WorkspaceGroupsUpdateRequest {
    /**
     * グループに追加するユーザーのIDリスト
     */
    addMembers?: string[];
    /**
     * グループアイコンURL
     */
    avatarUrl?: string;
    /**
     * グループ名
     */
    groupName?: string;
    /**
     * グループから除外するユーザーのIDリスト
     */
    removeMembers?: string[];
}

/**
 * Definition of workspace list response.
 */
export interface V1WorkspaceIndexResponse {
    /**
     * number of workspaces
     */
    count: number;
    /**
     * workspace list
     */
    workspaces: V1ObjectsWorkspace[];
}

/**
 * Definition of Create and invite workspace request.
 */
export interface V1WorkspaceInviteCreateRequest {
    /**
     * billing plan
     */
    billingPlan: BillingPlan;
    /**
     * brand
     */
    brand?: Brand;
    /**
     * contact email
     */
    contactEmail: string;
    /**
     * code name of workspace
     */
    displayId: string;
    /**
     * invited user id
     */
    invitedUser: string;
    /**
     * parent workspace Id
     */
    parentWorkspace: string;
}

/**
 * billing plan
 */
export enum BillingPlan {
    Free = "free",
    Internal = "internal",
}

/**
 * Definition of Create and invite workspace response.
 */
export interface V1WorkspaceInviteCreateResponse {
    /**
     * workspaces.displayId
     */
    displayId?: string;
    /**
     * workspaces.displayName
     */
    displayName?: string;
    /**
     * workspace_users.invitationEmail
     */
    emails: string[];
}

/**
 * Definition of Send workspace invite request.
 */
export interface V1WorkspaceInviteMailCreateRequest {
    /**
     * invitation email (cc)
     */
    cc?: string[];
    /**
     * displayId of workspace
     */
    displayId: string;
    /**
     * invitation email
     */
    email: string;
    /**
     * mail intro
     */
    intro: string;
    /**
     * signin limit days
     */
    signupLimitDays: number;
    /**
     * mail subject
     */
    subject: string;
}

/**
 * Definition of Send workspace invite response.
 */
export interface V1WorkspaceInviteMailCreateResponse {
    /**
     * send result
     */
    success: boolean;
}

/**
 * Definition of Create and invite workspace request. (Returning URL only)
 */
export interface V1WorkspaceInviteURLCreateRequest {
    /**
     * displayId
     */
    displayId: string;
    /**
     * invitation email
     */
    email: string;
    /**
     * Eメールなしで招待
     */
    withoutEmail?: boolean;
}

/**
 * Definition of Create and invite workspace response. (Returning URL only)
 */
export interface V1WorkspaceInviteURLCreateResponse {
    /**
     * invitation URL
     */
    url: string;
}

/**
 * Definition of Verify workspace sign-up request.
 */
export interface V1WorkspaceInviteVerifyCreateRequest {
    /**
     * ワークスペース表示ID
     */
    displayId?: string;
    /**
     * ワークスペースユーザーの招待メールアドレス
     */
    email: string;
    /**
     * 検証トークン
     */
    token: string;
}

/**
 * Definition of Verify workspace sign-up response.
 */
export interface V1WorkspaceInviteVerifyCreateResponse {
    /**
     * invited user
     */
    invitedUser?: string;
    /**
     * verify result
     */
    success: boolean;
    /**
     * workspace user
     */
    user?: V1ObjectsWorkspaceuserLarge;
    /**
     * workspace
     */
    workspace?: V1ObjectsWorkspace;
    /**
     * created workspace
     */
    workspaceCreated: boolean;
}

/**
 * Definition of verify and update workspace request.
 */
export interface V1WorkspaceInviteVerifyUpdateRequest {
    /**
     * avatar icon url
     */
    avatarUrl?: string;
    /**
     * workspace displayId
     */
    displayId: string;
    /**
     * workspace user email
     */
    email: string;
    /**
     * user password
     */
    password: string;
    /**
     * verify token
     */
    token: string;
    /**
     * workspace user
     */
    user: V1WorkspaceuserUpdateRequest;
    /**
     * Eメールなしで招待
     */
    withoutEmail?: boolean;
    /**
     * workspace
     */
    workspace: V1WorkspaceUpdateRequest;
}

/**
 * workspace user
 *
 * Definition of update workspace user request.
 */
export interface V1WorkspaceuserUpdateRequest {
    /**
     * workspace user active flag
     */
    active: boolean;
    /**
     * avatar icon url
     */
    avatarUrl?: string;
    /**
     * 連絡先Eメールアドレス
     */
    contactEmail: string;
    /**
     * Device Login user
     */
    deviceLoginUser: string;
    /**
     * email notification status
     */
    emailNotification?: V1ObjectsWorkspaceusersEmailNotification;
    /**
     * workspace user email
     */
    invitationEmail?: string;
    /**
     * workspace user language
     */
    language: Language;
    /**
     * workspace user name
     */
    name: string;
    /**
     * workspace user phonetic name
     */
    phoneticName: string;
    /**
     * mfp auth pin
     */
    pin: string;
    /**
     * role in the workspace
     */
    role: number;
}

/**
 * workspace
 *
 * Definition of update workspace request.
 */
export interface V1WorkspaceUpdateRequest {
    /**
     * active flag
     */
    active?: boolean;
    /**
     * contact email
     */
    contactEmail?: string;
    /**
     * code name of workspace
     */
    displayId: string;
    /**
     * name of workspace
     */
    displayName: string;
    /**
     * permit create child workspace
     */
    enableSubWorkspace: boolean;
    /**
     * language used in workspace
     */
    language: string;
    /**
     * logo url of workspace
     */
    logoUrl: string;
    /**
     * organization detail info
     */
    organizationDetail?: V1ObjectsWorkspacesOrganizationDetail;
}

/**
 * Definition of verify and update workspace response.
 */
export interface V1WorkspaceInviteVerifyUpdateResponse {
    /**
     * user object id
     */
    id: string;
    /**
     * workspace user
     */
    user: V1ObjectsWorkspaceuserLarge;
    /**
     * workspace
     */
    workspace: V1ObjectsWorkspace;
}

/**
 * Definition of create mfp and mfp users request.
 */
export interface V1WorkspaceMfpsCollectiveCreateRequest {
    /**
     * csv ファイル名
     */
    fileName: string;
    /**
     * 登録する機械番号リスト
     */
    req: V1ObjectsCollectiveMfp[];
    /**
     * 実行ワークスペースユーザー id
     */
    user: string;
}

/**
 * ワークスペース複合機の一括登録APIのレスポンス
 */
export interface V1WorkspaceMfpsCollectiveCreateResponse {
    /**
     * Number of successful invites
     */
    number?: number;
    status:  V1ObjectsModifyResponseStatusEnum;
}

/**
 * 新規ワークスペース複合機登録リクエスト
 */
export interface V1WorkspaceMfpsCreateRequest {
    /**
     * グループ名
     */
    groups: string[];
    /**
     * 機械番号
     */
    mfps: string[];
    /**
     * 登録した人
     */
    registeredUser: string;
    /**
     * 登録期限
     */
    registerLimitDays: number;
}

/**
 * Definition of create workspace response.
 */
export interface V1WorkspaceMfpsCreateResponse {
    /**
     * status of update mfp info
     */
    status: V1EndpointsStatusShowResponseStatus;
}

/**
 * Definition of delete workspace-mfp response.
 */
export interface V1WorkspaceMfpsDestroyResponse {
    /**
     * id of workspace-mfp
     */
    id: string;
}

/**
 * List mfps under workspace managerment
 */
export interface V1WorkspaceMfpsIndexResponse {
    /**
     * length of the list
     */
    limit?: number;
    /**
     * mfp list
     */
    mfps: V1ObjectsWorkspacemfp[];
    /**
     * pointer for list
     */
    offset?: number;
    /**
     * number of mfps under workspace
     */
    totalCount: number;
}

/**
 * List of workspace and MFP combinations request
 */
export interface V1WorkspaceMfpsListIndexRequest {
    /**
     * list of workspace id
     */
    subWorkspaceIds: string[];
}

/**
 * List of workspace and MFP combinations
 */
export interface V1WorkspaceMfpsListIndexResponse {
    /**
     * list of workspace and MFP combinations
     */
    combinations: V1ObjectsWorkspacesMfpCombination[];
}

/**
 * Definition of path workspace-mfp request.
 */
export interface V1WorkspaceMfpsPatchRequest {
    /**
     * status of mfps
     */
    status: V1ObjectsWorkspacemfpStatus;
}

/**
 * List mfps under workspace managerment
 */
export interface V1WorkspaceMfpsRegistersIndexResponse {
    /**
     * length of the list
     */
    limit?: number;
    /**
     * mfp list
     */
    mfps: V1ObjectsWorkspacesMfpInfo[];
    /**
     * pointer for list
     */
    offset?: number;
    /**
     * number of mfps under workspace
     */
    totalCount: number;
}

/**
 * workspace mfp info object
 */
export interface V1WorkspaceMfpsShowResponse {
    /**
     * name by administrator
     */
    deviceName: string;
    /**
     * registered flag
     */
    isRegistered: boolean;
    /**
     * name of mfp
     */
    mfpName: string;
    /**
     * mfp number
     */
    mfpNumber: string;
    /**
     * PIN-entry can be skipped or not
     */
    omitPin: boolean;
    /**
     * the registration pin
     */
    registrationPin: string;
    /**
     * status of mfp
     */
    status: V1ObjectsWorkspacemfpStatus;
}

/**
 * Definition of update workspace-mfp request.
 */
export interface V1WorkspaceMfpsUpdateRequest {
    /**
     * MFP's name in workspace.
     */
    deviceName?: string;
    /**
     * MFP's number.
     */
    mfpNumber: string;
    /**
     * PIN-entry can be skipped or not
     */
    omitPin?: boolean;
}

/**
 * Definition of update workspace mfp response.
 */
export interface V1WorkspaceMfpsUpdateResponse {
    /**
     * name by administrator
     */
    deviceName: string;
    /**
     * id of new workspacemfp
     */
    id: string;
    /**
     * registered flag
     */
    isRegistered: boolean;
    /**
     * mfp number
     */
    mfpNumber: string;
    /**
     * PIN-entry can be skipped or not
     */
    omitPin: boolean;
    /**
     * registration pin
     */
    registrationPin: string;
    /**
     * status of mfp
     */
    status: V1ObjectsWorkspacemfpStatus;
}

/**
 * Definition of create workspace mfp user request.
 */
export interface V1WorkspaceMfpsUsersCreateRequest {
    /**
     * グループ名
     */
    groups: string[];
    /**
     * user email list
     */
    users: string[];
}

/**
 * Definition of create workspace mfp user response.
 */
export interface V1WorkspaceMfpsUsersCreateResponse {
    /**
     * グループ一覧
     */
    groups: V1ObjectsGroup[];
    /**
     * user email list
     */
    users: V1ObjectsWorkspacemfpuser[];
}

/**
 * Definition of index workspace mfp user response.
 */
export interface V1WorkspaceMfpsUsersIndexResponse {
    /**
     * グループの取得件数
     */
    groupLimit?: number;
    /**
     * グループリストの取得開始位置
     */
    groupOffset?: number;
    /**
     * グループリスト
     */
    groups: V1ObjectsGroup[];
    /**
     * 登録されたグループの総数
     */
    groupTotalCount?: number;
    /**
     * メンバーの取得件数
     */
    limit?: number;
    /**
     * メンバーの取得開始位置
     */
    offset?: number;
    /**
     * 登録されたメンバーの総数
     */
    totalCount: number;
    /**
     * メンバーリスト
     */
    users: V1ObjectsWorkspacemfpuser[];
}

/**
 * Definition of Workspace users password reset response.
 */
export interface V1WorkspacePasswordResetCreateResponse {
    /**
     * sended emails
     */
    emails: string[];
}

/**
 * サインイン時にワークスペースのプロファイルを返すレスポンス
 */
export interface V1WorkspaceProfileShowResponse {
    /**
     * ワークスペースの表示ID
     */
    displayId: string;
    /**
     * ワークスペースの表示名
     */
    displayName: string;
    /**
     * ワークスペース言語
     */
    language: Language;
    /**
     * ワークスペースのロゴURL
     */
    logoUrl: string;
}

/**
 * 所属ワークスペースリマインドの結果をレスポンスする。結果はメールに送信されるのでレスポンスの値は一定である。
 */
export interface V1WorkspaceRemindMeIndexResponse {
    /**
     * リクエスト結果
     */
    status: V1WorkspaceRemindMeIndexResponseStatus;
}

/**
 * リクエスト結果
 */
export enum V1WorkspaceRemindMeIndexResponseStatus {
    Ok = "ok",
}

/**
 * Definition of workspace show response.
 */
export interface V1WorkspaceShowResponse {
    /**
     * workspace
     */
    workspace: V1ObjectsWorkspace;
}

/**
 * Definition of update workspace response.
 */
export interface V1WorkspaceUpdateResponse {
    /**
     * contact email
     */
    contactEmail?: string;
    /**
     * code name of workspace
     */
    displayId: string;
    /**
     * name of workspace
     */
    displayName: string;
    /**
     * permit create child workspace
     */
    enableSubWorkspace: boolean;
    /**
     * id of worksapceuser
     */
    id: string;
    /**
     * language used in workspace
     */
    language: string;
    /**
     * logo url of workspace
     */
    logoUrl: string;
    /**
     * organization detail info
     */
    organizationDetail?: V1ObjectsWorkspacesOrganizationDetail;
    /**
     * parent workspace Id
     */
    parentWorkspace: string;
}

/**
 * ワークスペースユーザー一括削除リクエストの定義
 */
export interface V1WorkspaceuserCollectiveDestroyRequest {
    /**
     * 削除したいワークスぺースユーザーのidの配列
     */
    users: string[];
}

/**
 * ワークスペースユーザー一括削除レスポンスの定義
 */
export interface V1WorkspaceuserCollectiveDestroyResponse {
    /**
     * 削除に成功したワークスぺースユーザーのidの配列
     */
    users: string[];
}

/**
 * Definition of create and invite workspace user request.
 */
export interface V1WorkspaceuserCreateRequest {
    /**
     * invitation emails
     */
    emails: string[];
    /**
     * invited user id
     */
    invitedUser: string;
    /**
     * number of days allowed to sign-up
     */
    signupLimitDays: number;
    /**
     * invitation source workspace id
     */
    workspace: string;
}

/**
 * Definition of create and invite workspace user response.
 */
export interface V1WorkspaceuserCreateResponse {
    /**
     * invited user list
     */
    users: V1ObjectsWorkspaceuserLarge[];
}

/**
 * Definition of delete workspace user response.
 */
export interface V1WorkspaceuserDestroyResponse {
    /**
     * email of workspace user
     */
    email: string;
}

/**
 * Definition of workspace user list response.
 */
export interface V1WorkspaceuserIndexResponse {
    /**
     * 総数
     */
    count: number;
    /**
     * ワークスペースユーザーリスト
     */
    users: V1ObjectsWorkspaceuserLarge[];
}

/**
 * ワークスペースユーザー一括登録リクエスト
 */
export interface V1WorkspaceuserInviteCollectiveCreateRequest {
    /**
     * 実行ファイル名
     */
    fileName: string;
    /**
     * 招待したworkspace_users._id
     */
    invitedUser: string;
    /**
     * サインアップ完了・パスワードのお知らせメール送信フラグ
     */
    sendEmail?: boolean;
    /**
     * 招待されたユーザー情報の配列
     */
    users: V1ObjectsCollectiveInvite[];
    /**
     * 招待するworkspaces._id
     */
    workspace: string;
}

/**
 * ワークスペースユーザー一括登録APIのレスポンス
 */
export interface V1WorkspaceuserInviteCollectiveCreateResponse {
    /**
     * 招待に成功した件数
     */
    number?: number;
    status:  V1ObjectsModifyResponseStatusEnum;
}

/**
 * ワークスペースユーザー一覧エクスポートリクエスト
 */
export interface V1WorkspaceuserInviteCollectiveExportCreateRequest {
    /**
     * 取得件数の上限。必ずlimitまで取得されるとは限らない。
     */
    limit?: number;
    /**
     * 取得開始位置
     */
    offset?: number;
    /**
     * エクスポート対象worksapces._id
     */
    workspace: string;
}

/**
 * ワークスペースユーザー一覧エクスポートレスポンス
 */
export interface V1WorkspaceuserInviteCollectiveExportCreateResponse {
    /**
     * 次の取得件数の上限
     */
    limit?: number;
    /**
     * 次のデータがあるかどうか
     */
    next?: boolean;
    /**
     * 次の取得開始位置
     */
    offset?: number;
    /**
     * 全ワークスペースユーザーの総数
     */
    total?: number;
    /**
     * ワークスペースユーザーの設定
     */
    users?: V1ObjectsCollectiveInvite[];
}

/**
 * Definition of Resend workspaceuser invite request.
 */
export interface V1WorkspaceuserInviteResendCreateRequest {
    /**
     * displayId of workspace
     */
    displayId: string;
    /**
     * invitation email
     */
    email: string;
    /**
     * Sign up limit days
     */
    signupLimitDays: number;
}

/**
 * Definition of Resend workspaceuser invite response.
 */
export interface V1WorkspaceuserInviteResendCreateResponse {
    /**
     * resend result
     */
    success: boolean;
}

/**
 * Definition of Verify workspaceuser invite request.
 */
export interface V1WorkspaceuserInviteVerifyCreateRequest {
    /**
     * invitation email
     */
    email: string;
    /**
     * verify token
     */
    token: string;
    /**
     * id of workspace
     */
    workspaceId: string;
}

/**
 * Definition of Verify workspaceuser invite response.
 */
export interface V1WorkspaceuserInviteVerifyCreateResponse {
    /**
     * invited user
     */
    invitedUser?: string;
    /**
     * verify result
     */
    success: boolean;
    /**
     * workspace user
     */
    user?: V1ObjectsWorkspaceuserLarge;
    /**
     * workspace
     */
    workspace?: V1ObjectsWorkspace;
}

/**
 * Definition of verify and update workspace user request.
 */
export interface V1WorkspaceuserInviteVerifyUpdateRequest {
    /**
     * avatar icon url
     */
    avatarUrl?: string;
    /**
     * workspace displayId
     */
    displayId: string;
    /**
     * workspace user email
     */
    email: string;
    /**
     * user password
     */
    password: string;
    /**
     * verify token
     */
    token: string;
    /**
     * signup member or admin signup insted of member
     */
    type?: Type;
    /**
     * workspace user
     */
    user: V1WorkspaceuserUpdateRequest;
}

/**
 * signup member or admin signup insted of member
 */
export enum Type {
    AdminSignup = "adminSignup",
    MemberSignup = "memberSignup",
}

/**
 * Definition of verify and update workspace user response.
 */
export interface V1WorkspaceuserInviteVerifyUpdateResponse {
    /**
     * user object id
     */
    id: string;
    /**
     * workspace user
     */
    user: V1ObjectsWorkspaceuserLarge;
    /**
     * workspace
     */
    workspace: V1ObjectsWorkspace;
}

/**
 * Definition of workspaceuser password reset request.
 */
export interface V1WorkspaceuserPasswordResetCreateRequest {
    /**
     * admin app
     */
    admin: boolean;
    /**
     * user email
     */
    email: string;
    /**
     * id of workspace
     */
    workspaceId: string;
}

/**
 * Definition of workspaceuser password response.
 */
export interface V1WorkspaceuserPasswordResetCreateResponse {
    /**
     * result
     */
    success: boolean;
}

/**
 * Definition of workspaceuser password reset update request.
 */
export interface V1WorkspaceuserPasswordResetUpdateRequest {
    /**
     * update password
     */
    password: string;
    /**
     * reset token
     */
    token: string;
}

/**
 * Definition of password reset update request.
 */
export interface V1WorkspaceuserPasswordResetUpdateResponse {
    /**
     * result
     */
    success: boolean;
}

/**
 * Definition of workspaceuser password reset verify request.
 */
export interface V1WorkspaceuserPasswordResetVerifyCreateRequest {
    /**
     * reset token
     */
    token: string;
}

/**
 * Definition of workspaceuser password verify response.
 */
export interface V1WorkspaceuserPasswordResetVerifyCreateResponse {
    /**
     * result
     */
    success: boolean;
    /**
     * workspace user
     */
    user?: V1ObjectsWorkspaceuserLarge;
    /**
     * workspace
     */
    workspace?: V1ObjectsWorkspace;
}

/**
 * Definition of workspace user show response.
 */
export interface V1WorkspaceuserShowResponse {
    /**
     * workspace user
     */
    user: V1ObjectsWorkspaceuserLarge;
}

/**
 * Definition of update workspace user response.
 */
export interface V1WorkspaceuserUpdateResponse {
    /**
     * workspace user
     */
    user: V1ObjectsWorkspaceuserLarge;
}

/**
 * ワークスペースユーザーの認可状況一覧
 */
export interface V1WorkspaceuserXStorageStatusIndexResponse {
    /**
     * レスポンスのユーザーの長さ
     */
    count: number;
    /**
     * ワークスペースユーザーリスト
     */
    users: V1ObjectsXStoragesStatus[];
}

/**
 * create x-storage request
 */
export interface V1XStoragesAuthCreateRequest {
    /**
     * password
     */
    password: string;
    /**
     * userID
     */
    user: string;
}

/**
 * create x-storage response
 */
export interface V1XStoragesAuthCreateResponse {
    /**
     * secret access key
     */
    accessKey: string;
}

/**
 * クラウドストレージ認可URL
 */
export interface V1XStoragesAuthorLinkIndexResponse {
    /**
     * link to grant access page
     */
    authorizationLink: string;
}

/**
 * クラウドストレージ認可作成リクエスト
 */
export interface V1XStoragesCreateRequest {
    /**
     * xstorage author code
     */
    authorCode: string;
    /**
     * X_storage_order_historyのID(認可依頼から作成時のみ必要)
     */
    orderId?: string;
    /**
     * アクセス可能なスコープ
     */
    scope?: string;
    /**
     * service Name
     */
    service: V1ObjectsServiceEnum;
    /**
     * share type
     */
    share: number;
}

/**
 * クラウド認可作成レスポンス
 */
export interface V1XStoragesCreateResponse {
    /**
     * Authorization exprie date
     */
    authorizationDeadline: string;
    /**
     * Authorization id (email)
     */
    authorizationId: string;
    /**
     * link to grand access page
     */
    authorizationLink: string;
    /**
     * 最後に認可発行したアプリの記録.Googleドライブアプリの統合に使用。
     */
    clientType?: V1ObjectsServiceEnum;
    /**
     * Xstorage background image
     */
    imageLink?: string;
    /**
     * x-storage method to conect to service server
     */
    is2L?: boolean;
    /**
     * service Name
     */
    service: V1ObjectsServiceEnum;
    /**
     * share type
     */
    share: number;
    /**
     * status notAuthored if no error
     */
    status: V1ObjectsXStorageStatus;
    /**
     * xstorages id
     */
    xstorageId: string;
}

/**
 * destroy x-storage response
 */
export interface V1XStoragesDestroyResponse {
    /**
     * xstorage Id
     */
    xstorageId: string;
}

/**
 * show xstorage folder (default root)
 */
export interface V1XStoragesFilesShowRequest {
    /**
     * Current cursor (for pagination).
     */
    cursor?: string;
    /**
     * filter to sort user of Business Card
     */
    filter?: string;
    /**
     * destination to get file (deault is root)
     */
    path?: string;
}

/**
 * Xstorage file response of tenant user
 */
export interface V1XStoragesFilesShowResponse {
    /**
     * Current cursor (for pagination).
     */
    cursor?: string;
    /**
     * Cloud storage destination
     */
    path: string;
    /**
     * フォルダ階層ごとにフォルダ名を格納する
     */
    pathArray?: V1ObjectsXstorageFile[];
    /**
     * List of items in a folder
     */
    xStorageFiles: V1ObjectsXstorageFile[];
}

/**
 * ユーザーのクラウドストレージ認可一覧取得リクエスト
 */
export interface V1XStoragesIndexRequest {
    /**
     * limit
     */
    limit?: string;
    /**
     * offset
     */
    offset?: string;
    /**
     * share type
     */
    share: string;
    /**
     * skip decrypt input data
     */
    skipDecryption: SkipDecryption;
    /**
     * status that should be returned
     */
    status?: V1ObjectsXStorageStatus;
    /**
     * user sub
     */
    userSub?: string;
    /**
     * workspace Id
     */
    workspaceId?: string;
}

/**
 * skip decrypt input data
 */
export enum SkipDecryption {
    False = "false",
    True = "true",
}

/**
 * クラウドストレージ一覧取得レスポンス
 */
export interface V1XStoragesIndexResponse {
    /**
     * pagination
     */
    pagination?: V1ObjectsPagination;
    /**
     * xstorages list
     */
    xstorages: V1ObjectsXStorage[];
}

/**
 * 新規クラウド認可作成依頼リクエスト
 */
export interface V1XStoragesOrderCreateRequest {
    /**
     * 許可ドメイン
     */
    allowedDomain: string;
    /**
     * メール本文
     */
    body: string;
    /**
     * 送信時のCC
     */
    cc: string[];
    /**
     * 認可依頼先情報
     */
    info?: V1ObjectsXStoragesOrderInfo[];
    /**
     * URLの期限
     */
    limit: number;
    /**
     * 依頼タイプ
     * eachUser: 選択したユーザー、グループのクラウドストレージに登録する
     * oneUser: 1ユーザーのクラウドストレージに全て登録する、共有クラウドストレージ
     */
    ordertype?: V1ObjectsRegisteredUserEnum;
    service:    V1ObjectsServiceEnum;
    /**
     * クラウドストレージ(0) 共有クラウドストレージ(1)
     */
    share?: number;
    /**
     * メールタイトル
     */
    title: string;
}

/**
 * クラウド認可作成依頼詳細一覧取得
 */
export interface V1XStoragesOrderDetailIndexResponse {
    /**
     * クラウド認可作成一覧
     */
    history: V1ObjectsXStoragesOrderHistory[];
    /**
     * 依頼のマスターデータ
     */
    master: V1ObjectsXStoragesOrderMaster;
    /**
     * pagination
     */
    pagination?: V1ObjectsPagination;
}

/**
 * クラウド認可作成依頼一覧取得
 */
export interface V1XStoragesOrderIndexResponse {
    /**
     * クラウド認可作成一覧
     */
    orders: V1ObjectsXStoragesOrderMaster[];
    /**
     * pagination
     */
    pagination?: V1ObjectsPagination;
}

/**
 * クラウド認可URL取得レスポンス
 */
export interface V1XStoragesOrderLinkShowResponse {
    /**
     * 認可URL
     */
    authorizationLink: string;
    /**
     * x_storage_order_historyのID
     */
    orderId: string;
    service: V1ObjectsServiceEnum;
}

/**
 * 新規クラウド認可作成依頼リクエスト
 */
export interface V1XStoragesOrderUpdateRequest {
    /**
     * 許可ドメイン
     */
    allowedDomain: string;
    /**
     * メール本文
     */
    body: string;
    /**
     * 送信時のCC
     */
    cc: string[];
    /**
     * 認可依頼先情報
     */
    info?: V1ObjectsXStoragesOrderInfo[];
    /**
     * URLの期限
     */
    limit: number;
    /**
     * 認可依頼履歴のID
     */
    orderHistoryId?: string;
    /**
     * 依頼タイプ
     * eachUser: 選択したユーザー、グループのクラウドストレージに登録する
     * oneUser: 1ユーザーのクラウドストレージに全て登録する、共有クラウドストレージ
     */
    ordertype?: V1ObjectsRegisteredUserEnum;
    service:    V1ObjectsServiceEnum;
    /**
     * クラウドストレージ(0) 共有クラウドストレージ(1)
     */
    share?: number;
    /**
     * メールタイトル
     */
    title: string;
}

/**
 * クラウドストレージ更新リクエスト
 */
export interface V1XStoragesUpdateRequest {
    /**
     * 認可コード
     */
    authorCode: string;
    /**
     * アクセス可能なスコープ
     */
    scope?: string;
    /**
     * 共有タイプ(0 個人、1 共有)
     */
    share: number;
}

/**
 * update xstorage response
 */
export interface V1XStoragesUpdateResponse {
    /**
     * Authorization exprie date
     */
    authorizationDeadline: string;
    /**
     * Authorization id (email)
     */
    authorizationId: string;
    /**
     * link to grand access page
     */
    authorizationLink: string;
    /**
     * client type
     */
    clientType?: V1ObjectsServiceEnum;
    /**
     * Xstorage background image
     */
    imageLink?: string;
    /**
     * x-storage method to conect to service server
     */
    is2L?: boolean;
    /**
     * service Name
     */
    service: V1ObjectsServiceEnum;
    /**
     * status notAuthored if no error
     */
    status: V1ObjectsXStorageStatus;
    /**
     * xstorages id
     */
    xstorageId: string;
}
