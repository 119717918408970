import querystring from 'querystring';
import * as schema from '@/bundles/schema/typescript/schema';
import { default as consts } from '@/common/constants';
import { AvailableMethod } from '@/common/constants/authorization';
import locale from '@/common/utils/locale';
import { EmailValidate } from '@/common/constants/email';
import * as validator from '@/common/utils/validator';
import { axiosFactory } from '@/common/utils/axiosFactory';

export const getWorkspaceMfp = async (wId: string, auth: string, offset?: string, limit?: string, search?: string, excludeProperty?:string): Promise<schema.V1WorkspaceMfpsIndexResponse> => {
    const query = querystring.stringify({
        offset,
        limit,
        search,
        excludeProperty,
    });
    const response = await axiosFactory.get<schema.V1WorkspaceMfpsIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const getRegisteringWorkspaceMfp = async (wId: string, auth: string, offset?: string, limit?: string): Promise<schema.V1WorkspaceMfpsRegistersIndexResponse> => {
    const query = querystring.stringify({
        offset,
        limit,
    });
    const response = await axiosFactory.get<schema.V1WorkspaceMfpsRegistersIndexResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps/registers?${query}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const getWorkspaceMfpInfo = async (wId: string, wMfpId: string, auth: string): Promise<schema.V1WorkspaceMfpsShowResponse> => {
    const response = await axiosFactory.get<schema.V1WorkspaceMfpsShowResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps/${wMfpId}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const createWorkspaceMfp = async (wId: string, createWorkspaceMfpDto: schema.V1WorkspaceMfpsCreateRequest, auth: string): Promise<schema.V1WorkspaceMfpsCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceMfpsCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps`, createWorkspaceMfpDto, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const createCollectiveMfpAndMfpUsers = async (wId: string, request: schema.V1WorkspaceMfpsCollectiveCreateRequest, auth: string): Promise<schema.V1WorkspaceMfpsCollectiveCreateResponse> => {
    const response = await axiosFactory.post<schema.V1WorkspaceMfpsCollectiveCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps/collective`, request, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const updateWorkspaceMfp = async (wId: string, wMfpId: string, updateWorkspaceMfpDto: schema.V1WorkspaceMfpsUpdateRequest, auth: string): Promise<schema.V1WorkspaceMfpsUpdateResponse> => {
    const responce = await axiosFactory.put<schema.V1WorkspaceMfpsUpdateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps/${wMfpId}`, updateWorkspaceMfpDto, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return responce.data;
};

export const updateStatusWorkspaceMfp = async (
    wId: string,
    wMfpId: string,
    updateStatusWorkspaceMfpDto: schema.V1WorkspaceMfpsPatchRequest,
    auth: string,
): Promise<schema.V1WorkspaceMfpsCreateResponse> => {
    const response = await axiosFactory.patch<schema.V1WorkspaceMfpsCreateResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps/${wMfpId}`, updateStatusWorkspaceMfpDto, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const destroyWorkspaceMfp = async (wId: string, id: string, auth: string): Promise<schema.V1WorkspaceMfpsDestroyResponse> => {
    const response = await axiosFactory.delete<schema.V1WorkspaceMfpsDestroyResponse>(`https://${consts.environment.api.hostname}/v1/workspaces/${wId}/mfps/${id}`, {
        headers: { Authorization: `${AvailableMethod.Bearer}${auth}` },
    });
    return response.data;
};

export const mfpNumberValidations = (): validator.Constraints => {
    return {
        mfpNumber: {
            mfpNumberFormat: {
                allow: false,
                message: locale.t(locale.keys.validation.mfpNumber),
            },
        },
    };
};

export const NewMfpNumberValidation = (): validator.ValidationOutput => {
    return {
        mfpNumber: null,
    };
};

export const emailValidations = (): validator.Constraints => {
    return {
        email: {
            userEmail: {
                message: locale.t(locale.keys.validation.email),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const emailorEveryoneValidations = (): validator.Constraints => {
    return {
        email: {
            userEmail: {
                message: locale.t(locale.keys.validation.emailorEveryone),
            },
            length: {
                maximum: EmailValidate.MAX_LENGTH,
                message: locale.t(locale.keys.validation.tooLong, { num: EmailValidate.MAX_LENGTH }),
            },
        },
    };
};

export const NewEmailValidation = (): validator.ValidationOutput => {
    return {
        email: null,
    };
};
